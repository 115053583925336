const CryptoJs = require('crypto-js');
const { v4 } = require('uuid');

const raw = (params) => {
    let keys = Object.keys(params);
    keys = keys.sort();
    const newArgs = {};
    keys.forEach((key) => {
        newArgs[key] = params[key];
    });
    let string = '';
    for (const k in newArgs) {
        string += `&${k}=${newArgs[k]}`;
    }
    string = string.substring(1);
    return string;
};

const md5 = (raw) => CryptoJs.MD5(raw).toString(CryptoJs.enc.Base64);

const sign = (params) => {
    const { secret, nonce, timestamp, data } = params;
    // 签名字段
  const signStr = `${data}
  ${timestamp}
  ${secret}
  ${nonce}`;
    // 加密字符串
    return md5(signStr);
};

function getlineupRequestHeaders(body = {},route="") {

    // appKey
    const key = 'testkey';
    // appSecret
    const secret = 'testsecret';
    const timestamp = Date.now() + '';
    const nonce = v4();

    let data = route;
    const query = {};

    if (query) {
        data += '\n';
        // 输出query升序排列后的字符串
        data += raw(query);
    }
    if (body) {
        // md5加密后的body
        data += '\n';
        data += md5(JSON.stringify(body));
    }
    const signature = sign({
        secret,
        timestamp,
        nonce,
        data,
    });

    const headers = {
        'x-ca-key': key,
        'x-ca-timestamp': timestamp,
        'x-ca-nonce': nonce,
        'x-ca-signature': signature,
        'Content-Type': 'application/json',
    };
    return headers
}

export { getlineupRequestHeaders }