import React, { Component, createRef, Fragment } from 'react';
import axios from "axios";
import "./index.css";
import { isPC, geturlparam } from "../../tools/index";
import Toast from "./toast"
import LoginLoading from "./loginLoading"
import { orgin } from '../../tools/config';
import Cookies from 'js-cookie'

const { v4 } = require('uuid');

let timer = null
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoginLoading: false,
            phoneNum: "",       //手机号
            selectInput: false,  //手机号输入框是否获取焦点
            codeImg: "",     //图形验证码的base64
            regCode: "",     //regcode
            imgCode: "",     //输入的图形验证码
            countdown: 60,    //倒计时
            messageCode: "",     //输入的短信验证码
            messageBtnStatus: true, // 短信验证码按钮状态控制
            showCountdown: false, //是否显示倒计时
            agreementStatus: "",  //同意协议弹框
            toastMessage: "",       //错误信息文本
            toastIcon: false,
            showToastStatus: false,     //是否显示toast
            mockStatus: false,
            login: Cookies.get('token') ? true : false, // 是否已经登录
            userInfo: {},   // 用户信息
            sceneId: "1",
            sceneDesc: "时空万象"
        };
        this.intervals = ""
    }
    componentDidMount() {
        if (isPC()) {
            this.props.history.replace("/homePc"+window.location.search)
        } else {
            const { login } = this.state
            this.getcode()
            this.getMockStatus()
            // 判断是否全屏
            let fullScreen = this.checkFullScreen();
            console.log('=====================fullScreen', fullScreen)
            if (fullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
            document.querySelector("body").style.background = "#fff"
            // 如果已登状态，获取用户信息
            if (login) {
                this.getUserInfo()
            }
            // 获取默认场景信息
            this.getSceneData()
        }

    }

    getSceneData=()=>{
        let sceneId = geturlparam().get("sceneId") || "undefined"
        axios.post(orgin + `/scenario/route`, { sceneId: sceneId }).then((res) => {
            if (res.status == 200) {
                const { data } = res.data
                this.setState({
                    sceneId: data.sceneId,
                    sceneDesc: data.displayName
                })
            } 
        })
    }

    getUserInfo = () => {
        axios({
            method: 'post',
            url: orgin + "/login/getInfoByCookie",
            data: { requestId: v4(), isGetInfo: true },
            headers: {
                'token': Cookies.get('token')
            }
        }).then((res) => {
            if (res.status == 200 && !res.data.success) {
                this.showToast("登录失效，请重新登录！")
                Cookies.remove('token')
                setTimeout(()=>{
                    this.setState({
                        login: false
                    })
                },1000)
                return false
            }
            if (res.status == 200) {
                this.setState({
                    userInfo: res.data.data,
                })
            }
        })
    }

    // 获取mock状态
    getMockStatus = () => {
        let data = {
            "key": "mock_api_data",
            "requestId": v4()
        }
        axios.post(orgin + "/switch/query", data, { timeout: 5000 }).then((res) => {
            if (res.data.success) {
                let status = res.data.data.value === "true" ? true : false
                this.setState({
                    mockStatus: status
                })
            }
        }).catch((res) => {
            this.setState({
                mockStatus: false
            })
        })
    }

    //获取图形验证码
    getcode = () => {
        const _this = this
        axios({
            method: 'get',
            url: "//user.yangbo.cctv.com/openapi/v2/user/verification/pic/code%3Fappid=YuZlCga1bLRpePYE",
            responseType: 'blob'
        }).then((res) => {
            var reader = new FileReader();
            reader.readAsDataURL(res.data);  // 转换为base64，可以直接放入a表情href
            console.log()
            reader.onload = function () {
                _this.setState({
                    codeImg: reader.result,
                    regCode: "reg-code=" + res.headers["reg-code"]
                })
            }
        })
    }

    // 输入框change事件
    inputChange = (e, key) => {
        this.setState({
            [key]: e.target.value
        })
    }
    // 判断是否显示手机号输入框的清空按钮
    showClear = () => {
        const { selectInput, phoneNum } = this.state
        if (phoneNum && phoneNum.length !== 0 && selectInput) {
            return true
        } else {
            return false
        }
    }
    downTime = () => {
        this.intervals = setTimeout(() => {
            if (this.state.countdown > 0) {
                this.setState({
                    countdown: this.state.countdown - 1
                })
                clearInterval(this.intervals)
                this.intervals = null
                this.downTime();
            } else {
                clearInterval(this.intervals)
                this.intervals = null
                this.setState({
                    countdown: 60,
                    showCountdown: false
                })
            }
        }, 1000)
    }
    // 发送短信验证码
    getMessageCode = () => {
        const { phoneNum, imgCode, regCode, showCountdown, agreementStatus, messageBtnStatus } = this.state
        // 按钮置灰点击不执行
        if (!messageBtnStatus) {
            return;
        }
        if (showCountdown) {
            return
        }
        if (!agreementStatus) {
            this.showToast("请勾选同意协议")
            return
        }
        if (phoneNum == '') {
            this.showToast("请输入手机号")
            return
        }

        let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (!reg.test(phoneNum)) {
            this.showToast("请输入正确的手机号")
            return
        }
        if (imgCode == "") {
            this.showToast("请输入图形验证码")
            return
        }

        // 加入校验逻辑 输入的图形验证码
        if (this.state.imgCode) {
            // 按钮变灰
            this.setState({
                countdown: 60,
                messageBtnStatus: false
            })
        }
        //图形验证码输入正确后，获取验证码变亮开始倒计时
        this.setState({
            showCountdown: true
        })
        this.downTime();
        //图形验证码输入错误，提示，并获取杨证明变亮

        /* this.intervals = setInterval(() => {
            if (this.state.countdown > 0) {
                this.setState({
                    countdown: this.state.countdown - 1
                })
            } else {
                clearInterval(this.intervals)
                this.intervals = null
                this.setState({
                    countdown: 60,
                    showCountdown: false
                })
            }
        }, 1000) */

        let data = {
            phoneNum: phoneNum,
            picCode: imgCode,
            cookie: regCode,
            requestId: v4()
        }
        axios.post(orgin + `/login/getSmsCode?phoneNum=${phoneNum}&picCode=${imgCode}&cookie=${regCode}&requestId=${data.requestId}`, data).then((res) => {
            if (res.data.success) {
                this.setState({
                    showCountdown: true
                })
                this.showToast("验证码发送成功", true)
            } else {
                this.showToast(res.data.errorMessage)
                this.getcode()
                this.setState({
                    showCountdown: false,
                    countdown: 60
                })
                // clearInterval(this.intervals)
                // this.intervals = ""
            }
            this.setState({
                messageBtnStatus: true
            })
        })
    }
    // 登录
    toLogin = () => {
        const { phoneNum, imgCode, messageCode, agreementStatus, mockStatus } = this.state
        if (!agreementStatus) {
            this.showToast("请勾选同意协议")
            return
        }
        if (phoneNum == '') {
            this.showToast("请输入手机号")
            return
        }

        let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (!reg.test(phoneNum)) {
            this.showToast("请输入正确的手机号")
            return
        }
        if (imgCode == "") {
            this.showToast("请输入图形验证码")
            return
        }
        if (messageCode == "" && mockStatus == false) {
            this.showToast("请输入短信验证码")
            return
        }
        this.setState({
            showLoginLoading: true
        })
        let u = ""
        try {
            u = navigator.userAgent.toLowerCase()
        } catch (err) { }
        if (u.indexOf('dingtalk') !== -1) {
            console.log("当前在钉钉内部")
        } else {
            this.requestFullscreens()
        }
    }
    // 显示toast
    showToast = (e, success) => {
        if (!timer) {
            this.setState({
                showToastStatus: true,
                toastMessage: e,
                toastIcon: success ? true : false
            })
            timer = setTimeout(() => {
                this.setState({
                    showToastStatus: false,
                    toastMessage: "",
                    toastIcon: false
                })
                timer = null
            }, 1500)
        }
    }

    requestFullscreens = () => {
        var de = document.documentElement;
        if (de.requestFullscreen) {
            de.requestFullscreen();
        } else if (de.mozRequestFullScreen) {
            de.mozRequestFullScreen();
        } else if (de.webkitRequestFullScreen) {
            de.webkitRequestFullScreen();
        }
    }
    // 检测是否处于全屏
    checkFullScreen = () => {
        let isFullScreen = false;
        // if (document.fullscreenEnabled || document.msFullscreenEnabled) {
        //     isFullScreen = window.fullScreen || document.webkitIsFullScreen;
        //     if (isFullScreen === undefined) {
        //         isFullScreen = false;
        //     }
        // }
        let status = document['fullscreenElement'] ||
            document['mozFullScreenElement'] ||
            document['msFullScreenElement'] ||
            document['webkitFullscreenElement'] || null
        if (status == null) {
            isFullScreen = false;
        }
        else {
            isFullScreen = true;
        }
        return isFullScreen;
    }

    changeUser=()=>{
        this.setState({
            login: false
        })
    }
    render() {
        const {
            showLoginLoading, phoneNum, codeImg, showCountdown, countdown,
            messageCode, agreementStatus, toastMessage, showToastStatus,
            toastIcon, mockStatus, messageBtnStatus, login, userInfo, sceneDesc
        } = this.state
        const { mobile } = userInfo || {}
        const { history } = this.props
        return <div className='loginPage'>
            <div style={{ display: showLoginLoading ? "none" : "block" }}>
                {/* 头部 */}
                <div className='login_top'>
                    <img onClick={() => { history.goBack() }} className='login_back' src="https://img.alicdn.com/imgextra/i1/O1CN01uZ3pEd1OKgxuZfVZy_!!6000000001687-2-tps-70-70.png" alt="" />
                    <div className='login_title'>{login ? "欢迎回到央博宇宙" : "领取央博宇宙居民证"}</div>
                </div>
                {/* 输入区域 */}
                {
                    login ? <Fragment>
                        <div className='login_box'>
                            <img className='login_box_img' src="https://img.alicdn.com/imgextra/i2/O1CN013WK7qg1dfVCsB0Hnm_!!6000000003763-2-tps-622-86.png" alt="" />
                            <div className='login_box_phone'>{mobile ? mobile.substring(0, 3) + '****' + mobile.substring(7, 11) : null}</div>
                        </div>
                        <div className='login_btn' onClick={() => { history.replace(`/limbo`+window.location.search) }}></div>
                        <div className='login_changeUser' onClick={()=>{ this.changeUser() }}>切换账号</div>
                    </Fragment>
                        :
                        <Fragment>
                            <div className='login_form'>
                                <div className='login_phoneNum'>
                                    <input className='login_input' type="text"
                                        placeholder='请输入手机号'
                                        value={phoneNum}
                                        onFocus={() => { this.setState({ selectInput: true }) }}
                                        onBlur={() => { setTimeout(() => { this.setState({ selectInput: false }) }, 100) }}
                                        onChange={(e) => { this.inputChange(e, "phoneNum") }} />
                                    {
                                        this.showClear() ? <img onClick={() => { this.setState({ phoneNum: "" }) }} src="https://img.alicdn.com/imgextra/i4/O1CN01NY9j6y1qbSi4N4jU8_!!6000000005514-2-tps-70-70.png" alt="" /> : null
                                    }
                                </div>
                                <div className='login_imgCode'>
                                    <input className='login_input' type="text" placeholder='请输入右侧字符'
                                        onChange={(e) => { this.inputChange(e, "imgCode") }}
                                    />
                                    <img onClick={() => { this.getcode() }} src={codeImg} alt="" />
                                </div>
                                {
                                    mockStatus ? null : <div className='login_messageCode'>
                                        <input className='login_input' type="text" placeholder='请输入验证码'
                                            onChange={(e) => { this.inputChange(e, "messageCode") }}
                                        />
                                        {/* <span style={{ color: showCountdown ? "rgba(153, 153, 153, 1)" : "#A16D4A" }} onClick={() => { this.getMessageCode() }}>{showCountdown ? `${countdown}s后重新发送` : "获取验证码"}</span> */}
                                        <span style={{ color: messageBtnStatus ? (showCountdown ? "rgba(153, 153, 153, 1)" : "#A16D4A") : '#ccc' }} onClick={() => { this.getMessageCode() }}>{showCountdown ? `${countdown}s后重新发送` : "获取验证码"}</span>
                                    </div>
                                }
                            </div>
                            <div className='login_btn' onClick={() => { this.toLogin() }}></div>
                            <div className='login_agreement'>
                                <img onClick={() => {
                                    this.setState({
                                        agreementStatus: !agreementStatus
                                    })
                                }} src={agreementStatus ? "https://img.alicdn.com/imgextra/i1/O1CN01a61ze51ESLQZoeV39_!!6000000000350-2-tps-36-36.png" : "https://img.alicdn.com/imgextra/i4/O1CN01TBm08T1eLddT1QhBA_!!6000000003855-2-tps-35-36.png"} alt="" />
                                <span style={{ color: "rgba(176, 178, 196, 1)" }}>同意并阅读</span>
                                <span onClick={() => { window.open("https://api.yangbo.cctv.com/user/agreement") }}>《用户协议》</span>
                                <span onClick={() => { window.open("https://api.yangbo.cctv.com/privacy/policy") }}>《隐私协议》</span>
                            </div>
                        </Fragment>
                }
            </div>
            {
                showLoginLoading ? <LoginLoading 
                mockStatus={mockStatus} 
                requestFullscreens={() => { this.requestFullscreens() }} 
                history={history} 
                phoneNum={phoneNum} 
                messageCode={messageCode} 
                sceneDesc={sceneDesc}
                back={() => { this.setState({ showLoginLoading: false }) }} /> : null
            }

            {
                showToastStatus ? <Toast message={toastMessage} toastIcon={toastIcon} /> : null
            }
        </div>
    }
}
export default Index;