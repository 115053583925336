import React, { createRef } from "react";
import "./index.css"
import Modal from "./modal"
import { isPC, geturlparam, initSource } from "../../tools/index";
import jsbridges from "../../../src/tools/jsbridge.js"
import axios, { Axios } from "axios";
import { orgin } from '../../tools/config';

let gcs = window.gcsObj
var evt = "onorientationchange" in window ? "orientationchange" : "resize";
class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sourceType: "",
            modalStatus: false,
            supportStatus: true,
            hideAliLogo: false,
            btnClickStatus: false,
            noticeStatus: false,    // 公告弹框状态
            noticeData: {}, // 公告内容数据
            noticeResult: false, // 查询公告接口是否有返回
            source: {}
        }
        this.homePageRef = createRef()
    }

    componentDidMount() {
        if (isPC()) {
            this.props.history.replace("/homePc" + window.location.search)
        } else {
            let sourceType = geturlparam().get("sourceType")
            this.setState({
                sourceType,
                hideAliLogo: sessionStorage.getItem("ybAliLogo") ? true : false
            }, () => {
                sessionStorage.setItem("ybAliLogo", true)
                setTimeout(() => {
                    this.setState({
                        hideAliLogo: true
                    })
                }, 2000)
            })
            // 加载gcs
            this.loadingGcs()
            // 延时设置高度
            setTimeout(() => {
                this.homePageRef.current.style.height = document.documentElement.clientHeight + "px"
                this.homePageRef.current.style.width = document.documentElement.clientWidth + "px"
            }, 100)
            // 判断是否全屏
            if (sourceType !== "app") {
                let fullScreen = this.checkFullScreen();
                console.log('=====================fullScreen', fullScreen)
                if (fullScreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                }
            }
            document.querySelector("body").style.background = "#fff"

            if (!isPC()) {
                const _this = this
                window.addEventListener(evt, function () {
                    setTimeout(() => {
                        _this.orientationToggle()
                    }, 500)
                }, false);
            }
            initSource((source) => {
                if (source) {
                    document.documentElement.style.setProperty('--background', `url("${source.background}")`)
                    // document.documentElement.style.setProperty('--button', `url("${source.button}")`)
                    this.setState({ source })
                }
            })
        }
    }

    getGameStatus = () => {
        const data = {
            swKey: "deploy-notice",
            swType: "notice",
            "noticeId": "home"
        }
        axios.post(orgin + "/announcement/list", data).then((res) => {
            console.log(res)
            if (res.status == 200) {
                let data = res.data.data || []
                let obj = data[0] || {}
                this.setState({
                    noticeStatus: obj.status ? true : false,
                    noticeData: obj,
                })
            }
        }).finally(() => {
            this.setState({
                noticeResult: true
            })
        })
    }

    loadingGcs = () => {
        const _this = this
        gcs.isSupport().then(res => {
            console.log("浏览器支持", res)
            _this.getGameStatus()
            // 可用
        }).catch(err => {
            console.log('GCSSDK not support: ', err)
            _this.notSupport()
            // { type: '10', code: '109010', message: '当前浏览器不支持' }
            // { type: '10', code: '109011', message: '浏览器版本过低' }
            // { type: '10', code: '109012', message: '不支持webrtc' }
            // { type: '10', code: '109013', message: '不支持H264' }
        }).finally((respons) => {
            console.log("isSupport==>finally")
            this.setState({
                btnClickStatus: true
            })
        })
    }

    notSupport = () => {
        this.setState({
            modalStatus: true,
            supportStatus: false
        })
    }

    closeModal = () => {
        this.setState({
            modalStatus: false
        })
    }

    exitYbApp = () => {
        const params = {
            "type": 1007,
            "param": {

            }
        };
        jsbridges.callHandler('yangboNativeMethod', params, (responseData) => {
            console.log("exitYbApp", responseData)
        });
    }

    toNextPage = () => {
        const { sourceType, supportStatus, btnClickStatus, noticeResult } = this.state
        let search = window.location.search || ''
        if (!btnClickStatus) {
            return
        }
        if (!supportStatus) {
            this.setState({
                modalStatus: true
            })
            return
        }
        if (!noticeResult) {
            return
        }
        const data = {
            swKey: "deploy-notice",
            swType: "notice",
            "noticeId": "home"
        }
        const datas = {
            swKey: "is-show-video",
            swType: "video",
        }
        let pro1 = axios.post(orgin + "/announcement/list", data)
        let pro2 = axios.post(orgin + "/meta-switch/list", datas)
        Promise.all([pro1, pro2]).then(([res1, res2]) => {
            if (res1.status == 200) {
                let data = res1.data.data || []
                let obj = data[0] || {}
                let preRelease = obj.preRelease ? 1 : 0
                if(preRelease == 1 && obj.status==1){
                    this.setState({
                        noticeStatus: true,
                        noticeData: obj,
                    })
                    return
                }
            }
            if (sourceType === 'app') {
                this.props.history.push(`/limbo${search}`)
                return
            }
            if (res2.status == 200 && res2.data.data && res2.data.data[0] && res2.data.data[0].swValue === "true") {
                this.props.history.push(`/introduce${search}`)
            } else {
                this.props.history.push(`/login${search}`)
            }
        }).catch((err) => {
            if (sourceType === 'app') {
                this.props.history.push(`/limbo${search}`)
                return
            }
            this.props.history.push(`/introduce${search}`)
        })
    }

    setPageHgeiht = () => { }
    // 检测是否处于全屏
    checkFullScreen = () => {
        let isFullScreen = false;
        // if (document.fullscreenEnabled || document.msFullscreenEnabled) {
        //     isFullScreen = window.fullScreen || document.webkitIsFullScreen;
        //     if (isFullScreen === undefined) {
        //         isFullScreen = false;
        //     }
        // }
        let status = document['fullscreenElement'] ||
            document['mozFullScreenElement'] ||
            document['msFullScreenElement'] ||
            document['webkitFullscreenElement'] || null
        if (status == null) {
            isFullScreen = false;
        }
        else {
            isFullScreen = true;
        }
        return isFullScreen;
    }
    orientationToggle = () => {
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        const { current } = this.homePageRef;
        if (current) {
            current.style.width = width + "px"
            current.style.height = height + "px"
        }
    }
    render() {
        const { sourceType, modalStatus, hideAliLogo, noticeStatus, noticeData, source } = this.state
        const { title, content } = noticeData
        return <div className="homePage" ref={this.homePageRef} style={{
            width: document.documentElement.clientWidth + "px",
            height: document.documentElement.clientHeight + "px"
        }}>
            <img className="home_ybLogo" src={source.logo} alt="" />
            {
                sourceType === "app" ? <div className="home_backBtn" onClick={() => { this.exitYbApp() }}>
                    返回
                </div> : null
            }

            {
                sourceType === "app" ? <div className="home_detail_app">{source.text1}</div> : <div className="home_detail_h5">
                    <div>{source.text1}</div>
                    <div>{source.text2}</div>
                    <div>{source.text3}</div>
                    <div style={{ color: "rgba(219, 190, 119, 1)" }} onClick={() => {
                        window.location.href = 'https://sj.qq.com/appdetail/com.cctv.apps.museum'
                    }}>{source.text4}</div>
                </div>
            }
            <div></div>

            <img className="home_btn" src={source.button} onClick={() => { this.toNextPage() }} />
            {
                hideAliLogo ? null : <div className="home_ali">阿里云提供计算服务</div>
            }
            {
                modalStatus ? <Modal onClick={() => { this.closeModal() }} /> : null
            }
            {
                noticeStatus ? <Modal title={title} contentText={content} onClick={() => { this.setState({ noticeStatus: false }) }} /> : null
            }
        </div>
    }
}

export default Home;