import React, { Component, createRef, Fragment } from 'react';
import axios from "axios";
import "./index.css"
import { isPC, rotateScreen, geturlparam, isIos } from "../../tools/index"
import jsbridges from "../../../src/tools/jsbridge.js"
import { videoAddEventListener, videoRemoveEventListener } from "../../tools/videoEventListene"
import Modal from "./modal"
import Toast from './toast';
import { orgin, videoOrgin } from '../../tools/config';
const { v4 } = require('uuid');

var evt = "onorientationchange" in window ? "orientationchange" : "resize";
let MeetSDKs = window.MeetSDKs
let meetSDK = ''
class LoadingGames extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: [],
            viodeReady: false,
            voiceStatus: false,
            videoMessage: "央博宇宙资源准备中…",
            showVideoLoading: true,
            videoPlayStatus: "loading", // 视频播放的状态 loading:加载中，failed:自动播放失败
            clickSaveImgStatus: false,  // 若是扫码情况下，UE点击保存按钮，展示在我们的页面上
            saveImgUrl: "", // 若是扫码情况下，UE点击保存按钮，需要保存的图片url
            showModal: false,   // 显示弹框
            modalContentText: "",   //弹框内容
            modalBtnNeedBack: true,
            showToast: false,    // 轻提示
            toastText: "",  //轻提示内容
            fullscreenStatus: true,     //当前的全屏状态
            rotateStatus: true,     //屏幕的旋转状态，竖屏为TRUE，横屏为false
        };
        this.loadingGamesRef = createRef()
        this.toastTime = ""
        this.videoIndex = Math.floor(Math.random() * 2)
    }
    componentDidMount() {
        const _this = this;
        if (isPC()) {
            _this.props.history.replace("/homePc")
        } else {
            try {
                jsbridges.registerHandler("yangboWebMethod", (responseData) => {
                    console.log("shareImg接收参数的方法", responseData)
                })
            } catch (err) { }
            videoAddEventListener(document.querySelector(".loadingGames_video"), this.videoPlay, this.playFailed)
            document.querySelector("body").style.background = "#000"
            rotateScreen(_this.loadingGamesRef, evt, this.getFullListen)
            // 监听浏览器关闭
            this.listenWindowClose()

            // 监听全屏事件
            // document.addEventListener("fullscreenchange", this.fullListen)
            this.fullListen()

            const fullscreenEvts = {
                'requestFullscreen': 'fullscreenchange',
                'webkitRequestFullscreen': 'webkitfullscreenchange',
                'mozRequestFullScreen': 'mozfullscreenchange',
                'msRequestFullscreen': 'msfullscreenchange'
            }
            const roots = document.documentElement
            let evtName = ''
            for (let key in fullscreenEvts) {
                if (roots[key]) {
                    evtName = fullscreenEvts[key]
                    break
                }
            }
            if (document.addEventListener) {
                document.addEventListener(evtName, this.fullListen)
            }

            let sourceType = geturlparam().get("sourceType")
            // 对从app跳转过来的进行处理
            if (sourceType === 'app') {
                const userInfo = {
                    "type": 1001,
                    "param": {
                        "needLogin": 1
                    }
                };
                jsbridges.callHandler('yangboNativeMethod', userInfo, (responseData) => {
                    console.log("jsbridges====>", responseData)
                    const appUser = JSON.parse(responseData);
                    const nickname = appUser.userName
                    const uid = appUser.userId
                    if (appUser.phone && appUser.phone !== '') {
                        let datas = {
                            requestId: v4(),
                            phoneNum: appUser.phone
                        }
                        // 增加个解密手机号码的接口调用，并传递手机号码到
                        axios.post(orgin + '/user/decrypt', datas).then((res) => {
                            console.log('-------/user/decrypt-----', res);
                            if (res.data && res.data.success) {
                                const mobile = res.data.data
                                _this.initializationGcs(uid, nickname, mobile)
                            }
                        })
                    } else {
                        _this.initializationGcs(uid, nickname)
                    }

                });
            } else {
                const uid = geturlparam().get("uid")
                const nickname = decodeURIComponent(geturlparam().get("nickname"))
                const mobile = geturlparam().get("mobile")
                const sign = geturlparam().get("sign")
                this.initializationGcs(uid, nickname, mobile, sign)
            }
        }
    }

    listenWindowClose = ()=>{
        let _beforeUnload_time = 0, _unload_time = 0
        window.addEventListener('beforeunload', () => {
            _beforeUnload_time = new Date().getTime()

        })
        window.addEventListener('unload', () => {
            _unload_time = new Date().getTime()
            if (_unload_time - _beforeUnload_time <= 1) {
                // 关闭浏览器执行的方法
                console.log("浏览器关闭")
                try {
                    console.log("页面刷新，调用stop");
                    meetSDK.stop()
                    document.querySelector("#renderGcsDom").innerHTML = ""
                    document.querySelector("#renderGcsDom").style.width = 0
                    meetSDK = null
                } catch (err) { }

            }else{
                
            }

        })
    }

    getFullListen = (e) => {
        this.setState({
            rotateStatus: e
        })
    }
    // 判断全屏
    fullListen = () => {
        let status = document['fullscreenElement'] ||
            document['mozFullScreenElement'] ||
            document['msFullScreenElement'] ||
            document['webkitFullscreenElement'] || null
        console.log("监听全屏非全屏事件", status)
        if (status == null) {
            this.setState({
                fullscreenStatus: false
            })
        }
        else {
            this.setState({
                fullscreenStatus: true
            })
        }
    }

    playFailed = () => {
        this.setState({
            voiceStatus: true
        }, () => {
            document.querySelector(".loadingGames_video").play().then(() => {

            }).catch(() => {
                this.setState({
                    videoPlayStatus: "failed",
                })
            })

        })
    }

    videoPlay = () => {
        this.setState({
            showVideoLoading: false
        })
    }

    initializationGcs = (uid, nickname, mobile, sign) => {
        const _this = this
        let source = geturlparam().get("sourceType")
        let sceneId = geturlparam().get("sceneId")
        let userType = geturlparam().get("userType")
        if (!source) {
            source = "mobileH5"
        }
        if (!sceneId) {
            sceneId = "2"
        }
        const config = {
            uid,
            nickname,
            source,
            sceneId,
            userType: userType && !isNaN(Number(userType)) ? userType - 0 : 0,
            tagId: "renderGcsDom"
        }
        if (source!=='app' && sign) {
            config.sign = sign
        }
        if (mobile) {
            config.mobile = mobile;
        }

        console.log("meetSDKconfig===>", config)
        meetSDK = new MeetSDKs(config)
        meetSDK.init().then((res) => {
            console.log("SDK的init结果", res)
            if (res) {
                meetSDK.start()
            }
        })
        meetSDK.on('MeetEvent', res => {
            const { type, message } = res;
            console.log(2222, res)
            switch (type) {
                case 'ready': //        
                    _this.setState({
                        videoMessage: "准备完成，立即体验"
                    })
                    // meetSDK.setVolume(1);//ios  
                    _this.setState({
                        voiceStatus: true
                    })
                    try {
                        videoRemoveEventListener(document.querySelector(".loadingGames_video"))
                    } catch (err) { }
                    setTimeout(() => {
                        _this.setState({
                            viodeReady: true
                        })
                    }, 1000)
                    break;
                case 'save': //  
                    _this.saveImg(message)
                    console.log(message); //
                    break;
                case 'share': //  
                    _this.shareImg(message)
                    console.log(message); //     
                    break;
                case 'exit': //
                    _this.goBackPage()
                    console.log(message); //        
                    break;
                case 'error': //  
                    _this.gcsError(message)
                    console.log(message); //       
                    break
                default:
                    break;

            }
        })
    }

    goBackPage = () => {
        if (!window.location.pathname.includes("home")) {
            try{
                meetSDK.setVolume(1)
            }catch(err){}
            try {
                console.log("调用stopStyle")
                meetSDK.stopStyle()
            } catch (err) { }
            document.querySelector("#renderGcsDom").innerHTML = ""
            document.querySelector("#renderGcsDom").style.width = 0
            meetSDK = null
            this.props.history.goBack()
            try {
                videoRemoveEventListener(document.querySelector(".loadingGames_video"))
            } catch (err) { }
        }
    }

    shareImg = (obj) => {
        let sourceType = geturlparam().get("sourceType")
        if (sourceType === 'app') {
            const userInfo = {
                "type": 1006,
                "param": {
                    "imageUrl": obj.url
                }
            };
            jsbridges.callHandler('yangboNativeMethod', userInfo, (responseData) => {
                console.log("shareImg", responseData)
                meetSDK.shareCallback(obj.id)
            });

        } else {
            this.showToast("该功能仅在央博App内执行")
        }
    }

    saveImg = (obj) => {
        let sourceType = geturlparam().get("sourceType")
        // 对从app跳转过来的进行处理
        if (sourceType === 'app') {
            const userInfo = {
                "type": 1009,
                "param": {
                    "imageUrl": obj.url
                }
            };
            jsbridges.callHandler('yangboNativeMethod', userInfo, (responseData) => {
                console.log("saveImg", responseData)
            });
        } else {
            this.setState({
                clickSaveImgStatus: true,
                saveImgUrl: obj.url
            })
        }

    }

    gcsError = (message) => {
        const { desc, code } = message
        console.log("显示错误", desc, code)
        if (code == "501030" || code == "501041" || code == "904010" || code == "904020" || code == "904030") {
            this.showToast(desc)
            return
        }
        if (code == "903010" || code == "903020" || code == "510002" || code=="603010" || code=="501040") {
            this.setState({
                showModal: true,
                modalContentText: desc,
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "901080") {
            this.setState({
                showModal: true,
                modalContentText: "欢迎继续体验~",
                modalBtnNeedBack: false
            })
            return
        }
        if (code == "510001" || code == "501053") {
            this.setState({
                showModal: true,
                modalContentText: "会话停止中，请1分钟以后再试",
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "510003") {
            this.setState({
                showModal: true,
                modalContentText: "非正常请求！",
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "904030") {
            this.setState({
                showModal: true,
                modalContentText: "重连失败，请1分钟以后再试",
                modalBtnNeedBack: true
            })
            return
        }
        this.showToast("服务器异常，请稍后重试")
    }

    showToast = (e) => {
        if (!this.toastTime) {
            this.setState({
                showToast: true,
                toastText: e,
            })
            this.toastTime = setTimeout(() => {
                this.setState({
                    showToast: false,
                    toastText: "",
                })
                this.toastTime = null
            }, 2000)
        }
    }

    componentWillUnmount() {
        document.querySelector("body").style.background = "transparent"
        try{
            meetSDK.setVolume(1)
        }catch(err){}
        try {
            console.log("调用stopStyle")
            meetSDK.stopStyle()
            // console.log("调用stop退出")
            // meetSDK.stop()
            document.querySelector("#renderGcsDom").innerHTML = ""
            document.querySelector("#renderGcsDom").style.width = 0
            // meetSDK = null
        } catch (err) { }
        try {
            videoRemoveEventListener(document.querySelector(".loadingGames_video"))
        } catch (err) { }
        window.removeEventListener(evt, null)
        // document.removeEventListener("fullscreenchange", this.fullListen)
        const fullscreenEvts = {
            'requestFullscreen': 'fullscreenchange',
            'webkitRequestFullscreen': 'webkitfullscreenchange',
            'mozRequestFullScreen': 'mozfullscreenchange',
            'msRequestFullscreen': 'msfullscreenchange'
        }
        const roots = document.documentElement
        let evtName = ''
        for (let key in fullscreenEvts) {
            if (roots[key]) {
                evtName = fullscreenEvts[key]
                break
            }
        }
        if (document.removeEventListener) {
            document.removeEventListener(evtName, this.fullListen)
        }

    }

    fullscreens = () => {
        const { fullscreenStatus } = this.state
        let element = document.documentElement;
        console.log("加载游戏页面,点击全屏按钮", fullscreenStatus)
        try {
            if (fullscreenStatus) {
                console.log("加载游戏页面,退出全屏", fullscreenStatus)
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                console.log("加载游戏页面,开启全屏", fullscreenStatus)
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
            }
        } catch (err) {
            console.log("加载游戏页面全屏功能失败=====>", err)
        }
    }

    render() {
        const { viodeReady, videoMessage, voiceStatus, showVideoLoading, videoPlayStatus, clickSaveImgStatus, saveImgUrl, showModal, modalContentText, modalBtnNeedBack, showToast, toastText, fullscreenStatus, rotateStatus } = this.state
        return <div className='loadingGames_page'>
            <div className='loadingGames_content'
                ref={this.loadingGamesRef}
                style={{
                    zIndex: !viodeReady || clickSaveImgStatus || showModal || showToast ? 1000 : 0
                }}>

                {
                    !viodeReady && <div style={{ zIndex: !viodeReady ? 1000 : 0 }} >
                        <video id="loadingGames_video" className="loadingGames_video" autoPlay muted={voiceStatus} loop
                            poster='https://app-yangbo.cctv.com/yunmiaohui/yunmiaohui/fengmiantu03.jpg'
                            x5-playsinline="true"
                            playsInline
                            webkit-playsinline="true"
                            src={"https://" + videoOrgin[this.videoIndex] + "/flash/mp4video63/TMS/2023/01/18/2309b61c882cc90bb6d08e90c7790e64_2000_h264_1872_aac_128.mp4"}></video>
                        <div className='loadingGames_detail'>
                            <span>{videoMessage}</span>
                        </div>
                        {/* 声音按钮 */}
                        <img className='loadingGames_voice' src={!voiceStatus ? 'https://img.alicdn.com/imgextra/i4/O1CN01CV2xk41YsG9bkbIx8_!!6000000003114-2-tps-82-95.png' : "https://img.alicdn.com/imgextra/i2/O1CN01aubbSZ205XGDYlwX5_!!6000000006798-2-tps-82-95.png"}
                            onClick={() => {
                                this.setState({
                                    voiceStatus: !voiceStatus
                                })
                            }}
                        />
                        {
                            showVideoLoading ? <div className='loadingGamesVideo_loading_box' onClick={() => {
                                if (videoPlayStatus === "failed") {
                                    this.setState({
                                        videoPlayStatus: "loading",
                                        voiceStatus: false
                                    }, () => {
                                        document.querySelector(".loadingGames_video").play()
                                    })
                                }
                            }}>
                                <img className={videoPlayStatus == "failed" ? "loadingGamesLoading_box_failed" : "loadingGamesVideoLoading_box_loading"} src={videoPlayStatus == "failed" ? "https://img.alicdn.com/imgextra/i3/O1CN01u8uIMq1lUWdG35xpO_!!6000000004822-2-tps-44-46.png" : "https://img.alicdn.com/imgextra/i1/O1CN01p2X0Wc1WvnzPx3Qkp_!!6000000002851-2-tps-78-78.png"} alt="" />
                                <div>{videoPlayStatus == "failed" ? "点击播放" : "视频加载中…"}</div>
                            </div> : null
                        }
                    </div>
                }
                {
                    clickSaveImgStatus ? <div className='saveImg_box'>
                        <div className='saveImg_content'>
                            <img className='saveImg_img' src={saveImgUrl} alt="" />
                            <div className='saveImg_title'>
                                <img src="https://img.alicdn.com/imgextra/i1/O1CN01kpIc161saCrCRbAVX_!!6000000005782-2-tps-36-36.png" alt="" />
                                <span>长按保存图片</span>
                            </div>
                            <img onClick={() => { this.setState({ clickSaveImgStatus: false }) }} className='saveImg_closed' src="https://img.alicdn.com/imgextra/i3/O1CN01xQwO7N1DgiEf6PDxE_!!6000000000246-2-tps-60-60.png" alt="" />
                        </div>
                    </div> : null
                }
                {
                    showModal ? <Modal contentText={modalContentText} closed={() => {
                        this.setState({ showModal: false })
                        // modal的弹框，点击我知道了，是否返回上一页
                        if (modalBtnNeedBack) {
                            this.goBackPage()
                            // this.props.history.goBack()
                        } else {
                            console.log("调用了设置声音")
                            meetSDK.setVolume(1)
                        }
                    }} /> : null
                }
                {
                    showToast ? <Toast message={toastText} /> : null
                }
            </div>
            {
                geturlparam().get("sourceType") === "app" || isIos() ? null : <img className={` ${rotateStatus ? "loadingGames_fullscreen_btn" : "loadingGames_fullscreen_btn_false"}`} onClick={() => { this.fullscreens() }} src={fullscreenStatus ? "https://img.alicdn.com/imgextra/i3/O1CN01jNOa4K1RsX5B4wuNv_!!6000000002167-2-tps-208-208.png" : "https://img.alicdn.com/imgextra/i4/O1CN01cWjDVd1MXr7RKK830_!!6000000001445-2-tps-208-208.png"} alt="" />
            }

        </div>
    }
}
export default LoadingGames;