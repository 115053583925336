import React, { Component, createRef, Fragment } from 'react';
import axios from "axios";
import "./index.css";
import { isPC, initSource, geturlparam } from "../../tools/index";
import ToastPc from "../../component/ToastPc/index"
import LoadingPcLoading from "./loginPcLoading"
import { orgin } from '../../tools/config';
import Cookies from 'js-cookie'

let timer = null
const { v4 } = require('uuid');
class LoginPc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNum: "",   //手机号
            imgCode: "",     //输入的图形验证码
            smsCode: "",     //短信验证码
            toastMessage: "",  //提示框的文本
            showToastStatus: false,     //是否显示提示框
            messageBtnStatus: true, // 短信验证码按钮状态控制
            toastIcon: false,
            codeUrl: "",    //图形验证码的base64
            regCode: "",     //cookie
            showCountdown: false,   //是否显示倒计时
            countdown: 60,    //倒计时
            agreementStatus: false,     //是否同意协议
            showLoginPcLoading: false,
            mockStatus: false,
            login: Cookies.get('token') ? true : false,   // 是否已登录
            userInfo: {},   // 用户信息
            sceneId: "1",
            sceneDesc: "时空万象",
            source: {}
        };
        this.imgRef = createRef()

    }
    componentDidMount() {
        const { login } = this.state
        if (!isPC()) {
            this.props.history.replace("/home" + window.location.search)
        } else {
            this.getcode()
            this.getMockStatus()
            if (login) {
                this.getUserInfo()
            }
            initSource((source) => {
                if (source) {
                    this.setState({ source })
                }
            })
            this.getSceneData()
        }
    }

    getSceneData = () => {
        let sceneId = geturlparam().get("sceneId") || "undefined"
        axios.post(orgin + `/scenario/route`, { sceneId: sceneId }).then((res) => {
            if (res.status == 200) {
                const { data } = res.data
                this.setState({
                    sceneId: data.sceneId,
                    sceneDesc: data.displayName
                })
            } 
        })
    }

    getUserInfo = () => {
        axios({
            method: 'post',
            url: orgin + "/login/getInfoByCookie",
            data: { requestId: v4(), isGetInfo: true },
            headers: {
                'token': Cookies.get('token')
            }
        }).then((res) => {
            if (res.status == 200 && !res.data.success) {
                this.showToast("登录失效，请重新登录！")
                Cookies.remove('token')
                setTimeout(() => {
                    this.setState({
                        login: false
                    })
                }, 1000)
                return false
            }
            if (res.status == 200) {
                this.setState({
                    userInfo: res.data.data,
                })
            }
        })
    }

    getMockStatus = () => {
        let data = {
            "key": "mock_api_data",
            "requestId": v4()
        }
        axios.post(orgin + "/switch/query", data, { timeout: 5000 }).then((res) => {
            if (res.data.success) {
                let status = res.data.data.value === "true" ? true : false
                this.setState({
                    mockStatus: status
                })
            }
        }).catch((res) => {
            this.setState({
                mockStatus: false
            })
        })
    }

    getcode = () => {
        const _this = this
        axios({
            method: 'get',
            url: "//user.yangbo.cctv.com/openapi/v2/user/verification/pic/code%3Fappid=YuZlCga1bLRpePYE",
            responseType: 'blob'
        }).then((res) => {
            var reader = new FileReader();
            reader.readAsDataURL(res.data);  // 转换为base64，可以直接放入a表情href
            console.log()
            reader.onload = function () {
                _this.setState({
                    codeUrl: reader.result,
                    regCode: "reg-code=" + res.headers["reg-code"]
                })
            }
        })
    }

    logins = () => {
        const { phoneNum, imgCode, smsCode, agreementStatus, mockStatus } = this.state
        if (!agreementStatus) {
            this.showToast("请勾选同意协议")
            return
        }
        if (phoneNum == '') {
            this.showToast("请输入手机号")
            return
        }

        let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (!reg.test(phoneNum)) {
            this.showToast("请输入正确的手机号")
            return
        }
        if (imgCode == "") {
            this.showToast("请输入图形验证码")
            return
        }
        if (smsCode == "" && mockStatus == false) {
            this.showToast("请输入短信验证码")
            return
        }
        this.setState({
            showLoginPcLoading: true
        })
    }

    showToast = (e, success) => {
        if (!timer) {
            this.setState({
                showToastStatus: true,
                toastMessage: e,
                toastIcon: success ? true : false
            })
            timer = setTimeout(() => {
                this.setState({
                    showToastStatus: false,
                    toastMessage: "",
                    toastIcon: false
                })
                timer = null
            }, 1500)
        }
    }

    phoneChange = (e) => {
        this.setState({
            phoneNum: e.target.value
        })
    }
    inputChange = (e, key) => {
        this.setState({
            [key]: e.target.value
        })
    }
    downTime = () => {
        this.intervals = setTimeout(() => {
            if (this.state.countdown > 0) {
                this.setState({
                    countdown: this.state.countdown - 1
                })
                clearInterval(this.intervals)
                this.intervals = null
                this.downTime();
            } else {
                clearInterval(this.intervals)
                this.intervals = null
                this.setState({
                    countdown: 60,
                    showCountdown: false
                })
            }
        }, 1000)
    }
    getMessageCode = () => {
        const { phoneNum, imgCode, regCode, showCountdown, agreementStatus, mockStatus, messageBtnStatus } = this.state
        // 按钮置灰点击不执行
        if (!messageBtnStatus) {
            return;
        }
        if (showCountdown) {
            return
        }
        if (!agreementStatus) {
            this.showToast("请勾选同意协议")
            return
        }
        if (phoneNum == '') {
            this.showToast("请输入手机号")
            return
        }

        let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (!reg.test(phoneNum)) {
            this.showToast("请输入正确的手机号")
            return
        }
        if (imgCode == "") {
            this.showToast("请输入图形验证码")
            return
        }
        let data = {
            phoneNum: phoneNum,
            picCode: imgCode,
            cookie: regCode,
            requestId: v4()
        }
        axios.post(orgin + `/login/getSmsCode?phoneNum=${phoneNum}&picCode=${imgCode}&cookie=${regCode}&requestId=${data.requestId}`, data).then((res) => {
            if (res.data.success) {
                this.setState({
                    showCountdown: true
                })
                this.showToast("验证码发送成功", true)
            } else {
                this.showToast(res.data.errorMessage)
                this.getcode()
                this.setState({
                    showCountdown: false,
                    countdown: 60
                })
            }
            this.setState({
                messageBtnStatus: true
            })
        })
        // 加入校验逻辑 输入的图形验证码
        if (this.state.imgCode) {
            // 按钮变灰
            this.setState({
                messageBtnStatus: false,
                countdown: 60
            })
        }
        this.setState({
            showCountdown: true
        })
        this.downTime();
        /* let intervals = setInterval(() => {
            if (this.state.countdown > 0) {
                this.setState({
                    countdown: this.state.countdown - 1
                })
            } else {
                clearInterval(intervals)
                intervals = null
                this.setState({
                    countdown: 60,
                    showCountdown: false
                })
            }
        }, 1000) */

    }

    changeUser = () => {
        this.setState({
            login: false
        })
    }
    render() {
        const {
            smsCode, phoneNum, toastMessage, showToastStatus, codeUrl,
            showCountdown, countdown, agreementStatus, showLoginPcLoading,
            toastIcon, mockStatus, messageBtnStatus, login, userInfo, sceneDesc,
            source
        } = this.state
        const { mobile } = userInfo || {}
        const { history } = this.props
        return <div className='loginPcPage' style={{ backgroundImage: `url(${source?.background})` }}>
            <img className='loginPc_yblogo' src={source?.logo} alt="" />
            <div className='loginPcPage_form_mask'>
                <div className='loginPcPage_form'>
                    <div className={`loginPcPage_title ${login ? "" : mockStatus ? "loginPcPage_title_mock" : ""}`}>{login ? "欢迎回到央博宇宙" : "领取央博宇宙居民证"}</div>
                    {
                        login ? <div className='loginPc_box'>
                            <div className='loginPc_box_title'>当前已登录账号</div>
                            <div className='loginPc_box_phone'>{mobile ? mobile.substring(0, 3) + '****' + mobile.substring(7, 11) : null}</div>
                            <img className='loginPc_box_btn' onClick={() => { history.replace("/lineupPc" + window.location.search) }} src="https://img.alicdn.com/imgextra/i2/O1CN017usxBY1MUBx8f27mN_!!6000000001437-2-tps-804-136.png" alt="" />
                            <div className='loginPc_changeUser' onClick={() => { this.changeUser() }}>切换账号</div>
                        </div>
                            :
                            <Fragment>
                                <div className='loginPcPage_phoneNum'>
                                    <input className='loginPc_input' placeholder='请输入手机号' type="text" onChange={(e) => { this.inputChange(e, "phoneNum") }} />
                                </div>
                                <div className='loginPcPage_code'>
                                    <input className='loginPc_input' placeholder='请输入右侧字符' type="text" onChange={(e) => { this.inputChange(e, "imgCode") }} />
                                    <img onClick={() => { this.getcode() }} src={codeUrl} alt="" />
                                </div>
                                {
                                    mockStatus ? null : <div className='loginPcPage_smsCode'>
                                        <input className='loginPc_input' placeholder='请输入验证码' type="text" onChange={(e) => { this.inputChange(e, "smsCode") }} />
                                        <span style={{ color: messageBtnStatus ? (showCountdown ? "rgba(153, 153, 153, 1)" : "#A16D4A") : '#ccc' }} onClick={() => { this.getMessageCode() }}>{showCountdown ? `${countdown}s后重新发送` : "获取验证码"}</span>

                                    </div>
                                }

                                <img className='loginPcPage_btn' onClick={() => { this.logins() }} src="https://img.alicdn.com/imgextra/i2/O1CN017usxBY1MUBx8f27mN_!!6000000001437-2-tps-804-136.png" alt="" />
                                <div className='loginPc_agreement'>
                                    <img onClick={() => {
                                        this.setState({
                                            agreementStatus: !agreementStatus
                                        })
                                    }} src={agreementStatus ? "https://img.alicdn.com/imgextra/i1/O1CN01a61ze51ESLQZoeV39_!!6000000000350-2-tps-36-36.png" : "https://img.alicdn.com/imgextra/i4/O1CN01TBm08T1eLddT1QhBA_!!6000000003855-2-tps-35-36.png"} alt="" />
                                    <span style={{ color: "rgba(176, 178, 196, 1)" }}>同意并阅读</span>
                                    <span onClick={() => { window.open("https://api.yangbo.cctv.com/user/agreement") }}>《用户协议》</span>
                                    <span onClick={() => { window.open("https://api.yangbo.cctv.com/privacy/policy") }}>《隐私协议》</span>
                                </div>
                            </Fragment>
                    }
                </div>
            </div>
            {
                showLoginPcLoading ? <LoadingPcLoading
                    mockStatus={mockStatus}
                    history={history}
                    phoneNum={phoneNum}
                    smsCode={smsCode}
                    sceneDesc={sceneDesc}
                    back={() => { this.setState({ showLoginPcLoading: false }) }} /> : null
            }

            {
                showToastStatus ? <ToastPc message={toastMessage} toastIcon={toastIcon} /> : null
            }

        </div>
    }
}
export default LoginPc;