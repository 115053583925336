import React, { Component, createRef } from 'react';
import { isPC, geturlparam, initSource, getCdnUrl } from "../../tools/index"
import "./index.css";
import { videoAddEventListener, videoRemoveEventListener } from "../../tools/videoEventListene"
import { videoOrgin } from '../../tools/config';
import { getuserNumDate } from "../../tools/getuserNumDate"
var evt = "onorientationchange" in window ? "orientationchange" : "resize";
let intervals = ""
class Introduce extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: false,
            time: 5,
            isFirst: true,
            showSkip: false,
            voiceStatus: false,
            showVideoLoading: true,
            videoPlayStatus: "loading", // 视频播放的状态 loading:加载中，failed:自动播放失败
            isNotFromApp: true, // 没有从app做来
            fullscreenStatus: false,
            rotateStatus: true,     //屏幕的旋转状态，竖屏为TRUE，横屏为false
            source: {}
        };
        this.introducePageRef = createRef()
        this.introduceRef = createRef()
        this.videoRef = createRef()
        this.videoIndex = Math.floor(Math.random() * 2)
    }
    componentDidMount() {
        // app的埋点
        getuserNumDate()
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        const sourceType = geturlparam().get("sourceType")
        this.setState({
            isNotFromApp: sourceType!=='app'
        })
        if (width < height && sourceType !== "app") {
            this.setState({
                status: false
            })
            this.introduceRef.current.style.width = width;
            this.introduceRef.current.style.height = height;
        } else {
            this.setState({
                status: true
            })
        }


        if(width < height) {
            this.setState({
                rotateStatus: true
            })
        } else {
            this.setState({
                rotateStatus: false
            })
        }

        videoAddEventListener(document.querySelector(".introduceVideo"), this.videoPlay, this.playFailed)
        document.querySelector(".introduceVideo").addEventListener("ended", this.playEnd)
        setTimeout(()=>{
            this.videoPlay()
        },this.state.time)
        if(!isPC()) {
            // 监听全屏事件
            // document.addEventListener("fullscreenchange", this.fullListen)
            const fullscreenEvts = {
                'requestFullscreen': 'fullscreenchange',
                'webkitRequestFullscreen': 'webkitfullscreenchange',
                'mozRequestFullScreen': 'mozfullscreenchange',
                'msRequestFullscreen': 'msfullscreenchange',
            }
            const roots = document.documentElement
            let evtName = ''
            for (let key in fullscreenEvts) {
                if (roots[key]) {
                    evtName = fullscreenEvts[key]
                    break
                }
            }
            if (document.addEventListener) {
                document.addEventListener(evtName, this.fullListen)
            }

            console.log(evt, 'evt')  
            const _this = this
            window.addEventListener(evt, function () {           
                setTimeout(() => {
                    _this.orientationToggle()
                }, 500)
            }, false);
            initSource((source)=>{
                if(source) {
                    if(source.vdnUrl){
                        getCdnUrl(source.vdnUrl,(obj)=>{
                            this.setState({
                                source: obj
                            })
                        })
                        return
                    }
                    this.setState({source})
                }
            })
        }

        if(sourceType==='app') {
            this.rotateScreen()
        }
    }
    getFullListen = (e) => {
        this.setState({
            rotateStatus: e
        })
    }
    playFailed = () => {
        this.setState({
            voiceStatus: true
        }, () => {
            document.querySelector(".introduceVideo").play().then(() => {

            }).catch(() => {
                this.setState({
                    videoPlayStatus: "failed",
                })
            })

        })
    }
    videoPlay = () => {
        this.setState({
            showVideoLoading: false
        })
        if (intervals) {
            return
        }
        intervals = setInterval(() => {
            this.setState((v) => {
                if (v.time > 0) {
                    return {
                        time: v.time - 1
                    }
                } else {
                    clearInterval(intervals)
                    intervals = ""
                    return {
                        showSkip: true
                    }
                }
            })
        }, 1000);
    }
    playEnd = () => {
        this.skipNext()
    }
    componentWillUnmount() {
        try{
            clearInterval(intervals)
            intervals = ""
            
        }catch(err){}
        try {
            window.removeEventListener(evt, null)
            videoRemoveEventListener(document.querySelector(".introduceVideo"))
            document.querySelector(".introduceVideo").removeEventListener("ended", this.playEnd)
            // document.removeEventListener("fullscreenchange",this.fullListen)
        } catch (err) { }
        const fullscreenEvts = {
            'requestFullscreen': 'fullscreenchange',
            'webkitRequestFullscreen': 'webkitfullscreenchange',
            'mozRequestFullScreen': 'mozfullscreenchange',
            'msRequestFullscreen': 'msfullscreenchange'
        }
        const roots = document.documentElement
        let evtName = ''
        for (let key in fullscreenEvts) {
            if (roots[key]) {
                evtName = fullscreenEvts[key]
                break
            }
        }
        if (document.removeEventListener) {
            document.removeEventListener(evtName, this.fullListen)
        }
    }

    rotateScreen = () => {
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        this.computedIntroducePage(width, height)
        this.computedIntroduce(width, height)        
    } 
    orientationToggle = () => {
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        const {current} = this.introduceRef;
        if(current){
            current.style.width = width + "px"
            current.style.height = height + "px"
            current.style.top = 0
            current.style.left = 0
            current.style.transform = 'none'
            current.style.transformOrigin = '50% 50%'
        }

        if (width > height) {
            this.setState({
                rotateStatus: false,
                status: false
            })
            if(this.videoRef.current) {
                this.videoRef.current.style.objectFit='cover'
            }            
        } else {
            this.setState({
                rotateStatus: true,
                status: false
            })
            if(this.videoRef.current) {
                this.videoRef.current.style.objectFit='contain'
            }
        }  
    }
    computedIntroducePage = (width=0, height=0) => {
        const {current} = this.introducePageRef
        if(current){
            current.style.width = width+"px"
            current.style.height = height+"px"
        }
    }
    computedIntroduce = (width=0, height=0) => {
        const {current} = this.introduceRef
        if(current) {
            if(width>height) {
                current.style.width = width + "px"
                current.style.height = height + "px"
                current.style.top = 0
                current.style.left = 0
                current.style.transform = 'none'
                current.style.transformOrigin = '50% 50%'
                this.videoRef.current.style.objectFit='contain' 
                this.setState({
                    rotateStatus: false
                })              
            } else {
                current.style.width = height + "px"
                current.style.height = width + "px"
                current.style.top = (height - width) / 2 + "px"
                current.style.left = 0 - (height - width) / 2 + "px"
                current.style.transform = 'rotate(90deg)'
                current.style.transformOrigin = '50% 50%'
                this.videoRef.current.style.objectFit='cover' 
                this.setState({
                    rotateStatus: true
                }) 
            }
        }
    }
    change = () => {
        const { status } = this.state
        if (!status) {
            window.removeEventListener(evt, null)
            let width = document.documentElement.clientWidth;
            let height = document.documentElement.clientHeight;
            let {current} = this.introduceRef
            this.computedIntroducePage(width, height)
            if (current) {
                current.style.width = width + "px"
                current.style.height = height + "px"
                current.style.top = 0
                current.style.left = 0
                current.style.transform = 'none'
                current.style.transformOrigin = '50% 50%'
                this.videoRef.current.style.objectFit='contain'
            }
        } else {
            this.rotateScreen()
        }
        this.fullscreens(()=>{
            if(this.state.isFirst) {
                const _this = this
                // 解决首次全屏进来后视频未充满全屏问题
                setTimeout(() => {
                    let width = document.documentElement.clientWidth;
                    let height = document.documentElement.clientHeight;
                    _this.computedIntroduce(width, height)
                    _this.setState({
                        isFirst: false
                    })
                }, 500);
            }
        });
    }
    skipNext = () => {
        const sourceType = geturlparam().get("sourceType")
        let source = geturlparam().get("source")
        let sceneId = geturlparam().get("sceneId")
        if (!source) {
            source = "mobileH5"
        }
        if (!sceneId) {
            sceneId = "2"
        }
        // 从App过来的用户如果url上存在sourceType=u则不用走登录页面
        if (sourceType === 'app') {
            this.props.history.replace(`/lineup` + window.location.search)
        } else {
            this.props.history.replace("/login" + window.location.search)
        }
    }
    fullscreens = (cb) => {
        let element = document.documentElement;
        try{
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullScreen) {
                element.webkitRequestFullScreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                // IE11
                element.msRequestFullscreen();
            }
            cb && cb()
        }catch(err){
            console.log("介绍页页面全屏失败====>",err)
        }
    }
    // 退出全屏操作
    exitFullScreen() {
        const { fullscreenStatus } = this.state
        if (fullscreenStatus) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
        this.change()
    }
    fullListen = () => {
        let status = document['fullscreenElement'] ||
            document['mozFullScreenElement'] ||
            document['msFullScreenElement'] ||
            document['webkitFullscreenElement'] || null
        if (status == null) {
            this.setState({
                fullscreenStatus: false
            })
        }
        else {
            this.setState({
                fullscreenStatus: true
            })
        }
    }
    showToggleBtn =  () => {
        const {isNotFromApp, status, rotateStatus,fullscreenStatus } = this.state
        if(isNotFromApp) {
            
            if(!status && rotateStatus && !fullscreenStatus) {
                return true;
            } 
            if(!status && rotateStatus && fullscreenStatus) {
                return true;
            } 
            if(status && rotateStatus && !fullscreenStatus) {
                return true
            }
        }
        return false;
    }
    showToggle = () => {
        const {isNotFromApp, status, rotateStatus,fullscreenStatus } = this.state
        //isNotFromApp && rotateStatus
        if(isNotFromApp) {
            if(!rotateStatus) {
                return true;
            }  else if(status && rotateStatus && fullscreenStatus) {
                return true;
            }
        }        
        return false;
    }
    render() {
        const { status, time, showSkip, voiceStatus, showVideoLoading, videoPlayStatus,  rotateStatus, fullscreenStatus, source} = this.state
        return <div className='introducePage'
            ref={this.introducePageRef}
            style={{ 
                width: document.documentElement.clientWidth +"px",
                height: document.documentElement.clientHeight +"px",
                background: `url(${source.background})`,
                backgroundSize: '100% 100%'
             }}
             id="screen"
        >
            <div className='introducePageContent' ref={this.introduceRef}>
                {
                    // 不是从app过来的显示横竖屏按钮 
                    <div className={`${status || (!status && !rotateStatus && fullscreenStatus) ? "introduce_change" : "introduce_change_vertical"}`} onClick={() => { this.setState({ status: !status }, () => { this.change() }) }}>
                    {
                      this.showToggleBtn() && <img src="https://img.alicdn.com/imgextra/i4/O1CN01cWjDVd1MXr7RKK830_!!6000000001445-2-tps-208-208.png" alt="" />
                    }
                </div>
                }
                {
                    showSkip ? <div onClick={this.skipNext} className={`introducePage_skip ${status ? "introducePage_skip_transverse" : ""}`}>跳过</div> : <div className={`introducePage_time ${status ? "introducePage_time_transverse" : ""}`}>{`${(time>0&&time<10)?'0'+time:time}S后可跳过`}</div>
                }
                <div className='introduceVideo_box'>
                    <video ref={this.videoRef} 
                     poster={source.poster}
                    className={`introduceVideo ${status ? "introduceVideo_transverse" : ""}`} muted={voiceStatus} autoPlay
                        x5-playsinline="true"
                        playsInline
                        webkit-playsinline="true"
                        src={source.video} type="video/mp4">
                    </video>
                    {
                        // 从app跳转过来的对loading样式进行重写
                        showVideoLoading && <div className={`introduceVideoLoading_box ${showVideoLoading && geturlparam().get("sourceType")==='app'? 'loading_box':''}`} onClick={() => {
                            if (videoPlayStatus === "failed") {
                                this.setState({
                                    videoPlayStatus: "loading",
                                    voiceStatus: false
                                }, () => {
                                    document.querySelector(".introduceVideo").play()
                                })
                            }
                        }}>
                            <img className={videoPlayStatus == "failed" ? "introduceVideoLoading_box_failed" : "introduceVideoLoading_box_loading"} src={videoPlayStatus == "failed" ? "https://img.alicdn.com/imgextra/i3/O1CN01u8uIMq1lUWdG35xpO_!!6000000004822-2-tps-44-46.png" : "https://img.alicdn.com/imgextra/i1/O1CN01p2X0Wc1WvnzPx3Qkp_!!6000000002851-2-tps-78-78.png"} alt="" />
                            <div>{videoPlayStatus == "failed" ? "点击播放" : "视频加载中…"}</div>
                        </div>
                    }                    
                </div>
                {/* 声音按钮 */}
                <img className={`introduce_voice ${showSkip ? "introduce_voice_skip" : ""} ${status ? "introduce_voice_transverse" : ""}`} src={!voiceStatus ? 'https://img.alicdn.com/imgextra/i4/O1CN01CV2xk41YsG9bkbIx8_!!6000000003114-2-tps-82-95.png' : "https://img.alicdn.com/imgextra/i2/O1CN01aubbSZ205XGDYlwX5_!!6000000006798-2-tps-82-95.png"}
                    onClick={() => {
                        this.setState({
                            voiceStatus: !voiceStatus
                        })
                    }} />
            </div>
            {
                this.showToggle()  && <img className={` ${rotateStatus ? "loadingGames_fullscreen_btn" : "loadingGames_fullscreen_btn_false"}`} onClick={() => { 
                    if(rotateStatus) {
                        if(fullscreenStatus) {
                            this.setState({
                               status: false
                            },()=>{
                               this.exitFullScreen()
                            })
                          } else {
                           this.setState({
                               status: true
                            },()=>{
                               this.fullscreens()
                            })                    
                          }
                    } else {
                        if(fullscreenStatus) {
                            this.exitFullScreen()
                        } else {
                            this.fullscreens()
                        }
                    }
                    
                 }} src={fullscreenStatus ? "https://img.alicdn.com/imgextra/i3/O1CN01jNOa4K1RsX5B4wuNv_!!6000000002167-2-tps-208-208.png" : "https://img.alicdn.com/imgextra/i4/O1CN01cWjDVd1MXr7RKK830_!!6000000001445-2-tps-208-208.png"} alt="" />
            }
        </div>
    }
}
export default Introduce;