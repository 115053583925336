import React, { Fragment } from "react";
import "./modal.css"

function Modal({
    title = "系统提示",
    contentText="",
    onClick = () => null
}) {
    return <div className="modal_mask">
        <div className="modal_box">
            <div className="modal_title">{title ? title : "系统提示"}</div>
            <div className="modal_contents">{contentText?contentText:"受您系统限制，请您使用Chrome/微信/钉钉内置浏览器访问：https://meta.yangbo.cctv.com/"}</div>
            <div className="modal_btn" onClick={() => { onClick() }}>知道了</div>
        </div>
    </div>

}

export default Modal;