import React, { Component, createRef, Fragment } from 'react';
import LoadingGames from '../loadingGames';
import "./index.css"
import { isPC, geturlparam } from "../../tools/index"
import { videoAddEventListener, videoRemoveEventListener } from "../../tools/videoEventListene"
import Modal from "../../component/ModalPc/index"
import GamesToastPc from "./toast"
import { videoOrgin } from "../../tools/config"

let MeetSDKs = window.MeetSDKs
let meetSDK = ''
class LoadingGamesPc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viodeReady: false,
            videoMessage: "央博宇宙资源准备中…",
            showVideoLoading: true,
            voiceStatus: false,
            modalStatus: false,
            modalStatusContentText: "",
            modalBtnNeedBack: true,
            gamesToastPcStatus: false,
            toastMessage: "",
            showSaveImg: false,
            saveImgUrl: ""
        };
        this.videoRef = createRef()
        this.showToastTime = ""
        this.videoIndex = Math.floor(Math.random() * 2)
    }
    componentDidMount() {
        if (!isPC()) {
            this.props.history.replace("/home")
        } else {
            document.querySelector("body").style.background = "#000"
            this.initializationGcs()
            videoAddEventListener(document.querySelector(".loadingGamesPc_video"), this.videoPlay, this.playFailed)
            // window.onbeforeunload = function () { try { meetSDK.stop(); console.log("页面刷新111") } catch (err) { } }
            this.listenWindowClose()
        }
    }
    listenWindowClose = ()=>{
        let _beforeUnload_time = 0, _unload_time = 0
        window.addEventListener('beforeunload', () => {
            _beforeUnload_time = new Date().getTime()

        })
        window.addEventListener('unload', () => {
            _unload_time = new Date().getTime()
            if (_unload_time - _beforeUnload_time <= 1) {
                // 关闭浏览器执行的方法
                console.log("浏览器关闭")
                try {
                    console.log("页面刷新，调用stop");
                    meetSDK.stop()
                    document.querySelector("#renderGcsDom").innerHTML = ""
                    document.querySelector("#renderGcsDom").style.width = 0
                    meetSDK = null
                } catch (err) { }

            }else{
                
            }

        })
    }

    playFailed = () => {
        this.setState({
            voiceStatus: true
        }, () => {
            document.querySelector(".loadingGamesPc_video").play()
        })
    }

    videoPlay = () => {
        this.setState({
            showVideoLoading: false
        })
    }

    initializationGcs = (e) => {
        const _this = this
        const config = {
            uid: geturlparam().get("uid"),
            nickname: decodeURIComponent(geturlparam().get("nickname")),
            source: 'pc',
            sceneId: geturlparam().get("sceneId") && !isNaN(Number(geturlparam().get("sceneId"))) ? geturlparam().get("sceneId") : "2",
            userType: geturlparam().get("userType") && !isNaN(Number(geturlparam().get("userType"))) ? geturlparam().get("userType") - 0 : 0,
            tagId: "renderGcsDom"
        }
        if (geturlparam().get("mobile")) {
            config.mobile = geturlparam().get("mobile")
        }
        if(geturlparam().get("sign")) {
            config.sign = geturlparam().get("sign")
        }
        console.log("MeetSDKsConfig======>", config)
        meetSDK = new MeetSDKs(config)
        meetSDK.init().then((res) => {
            console.log("meetSdkInit", res)
            if (res) {
                meetSDK.start()
            }
        })
        meetSDK.on('MeetEvent', res => {
            const { type, message } = res;
            console.log(2222, res)
            switch (type) {
                case 'ready': //        
                    _this.setState({
                        videoMessage: "准备完成，立即体验"
                    })
                    // meetSDK.setVolume(1);//ios   
                    _this.setState({
                        voiceStatus: true
                    })
                    try {
                        videoRemoveEventListener(document.querySelector(".loadingGamesPc_video"))
                    } catch (err) { }
                    setTimeout(() => {
                        _this.setState({
                            viodeReady: true,
                        })
                    }, 1000)
                    break;
                case 'save': //  
                    _this.saveImg(message)
                    console.log(message); //      
                    break;
                case 'share': //  
                    _this.shareImg()
                    console.log(message); //     
                    break;
                case 'exit': // 
                    _this.goBackPage()
                    console.log(message); //        
                    break;
                case 'error': // 
                    _this.gcsError(message)
                    console.log(message); //       
                    break
                default:
                    break;

            }
        })
    }

    shareImg = () => {
        this.showToast("该功能仅在央博App内执行")
    }

    saveImg = (obj) => {
        this.setState({
            showSaveImg: true,
            saveImgUrl: obj.url
        })
    }

    goBackPage = () => {
        if (!window.location.pathname.includes("home")) {
            try{
                meetSDK.setVolume(1)
            }catch(err){}
            try{
                console.log("调用stopStyle")
                meetSDK.stopStyle()
            }catch(err){}
            console.log("当前页面====>", window.location.pathname)
            meetSDK = null
            document.querySelector("#renderGcsDom").innerHTML = ""
            document.querySelector("#renderGcsDom").style.width = 0
            document.querySelector("#renderGcsDom").style.height = 0
            this.props.history.goBack()
        }

    }

    componentWillUnmount() {
        document.querySelector("body").style.background = "transparent"
        try{
            meetSDK.setVolume(1)
        }catch(err){}
        try {
            console.log("调用stopStyle")
            meetSDK.stopStyle()
            // console.log("调用stop退出")
            // meetSDK.stop()
            document.querySelector("#renderGcsDom").innerHTML = ""
            document.querySelector("#renderGcsDom").style.width = 0
            document.querySelector("#renderGcsDom").style.height = 0
        } catch (err) { }
        try {
            videoRemoveEventListener(document.querySelector(".loadingGamesPc_video"))

        } catch (err) { }
    }

    gcsError = (message) => {
        const { desc, code } = message
        console.log("显示错误", desc, code)
        if (code == "501030" || code == "501041" || code == "904010" || code == "904020" || code == "904030") {
            this.showToast(desc)
            return
        }
        if (code == "903010" || code == "903020" || code == "510002" || code=="603010" || code=="501040") {
            this.setState({
                modalStatus: true,
                modalStatusContentText: desc,
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "901080") {
            this.setState({
                modalStatus: true,
                modalStatusContentText: "欢迎继续体验~",
                modalBtnNeedBack: false
            })
            return
        }
        if (code == "510001" || code == "501053") {
            this.setState({
                modalStatus: true,
                modalStatusContentText: "会话停止中，请1分钟以后再试",
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "510003") {
            this.setState({
                modalStatus: true,
                modalStatusContentText: "非正常请求！",
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "904030") {
            this.setState({
                modalStatus: true,
                modalStatusContentText: "重连失败，请1分钟以后再试",
                modalBtnNeedBack: true
            })
            return
        }
        this.showToast("服务器异常，请稍后重试")
    }

    showToast = (e) => {
        if (!this.showToastTime) {
            this.setState({
                gamesToastPcStatus: true,
                toastMessage: e,
            })
            this.showToastTime = setTimeout(() => {
                this.setState({
                    gamesToastPcStatus: false,
                    toastMessage: "",
                })
                this.showToastTime = null
            }, 2000)
        }
    }

    render() {
        const { viodeReady, videoMessage, showVideoLoading, voiceStatus, modalStatus, modalStatusContentText, modalBtnNeedBack, gamesToastPcStatus, toastMessage, showSaveImg, saveImgUrl } = this.state
        return <div className='loadingGamesPc_page' ref={this.pageRef}  >
            {
                viodeReady ? null : <div style={{ zIndex: viodeReady ? 0 : 1000 }}>
                    <video ref={this.videoRef} className="loadingGamesPc_video" autoPlay muted={voiceStatus}
                        poster="https://app-yangbo.cctv.com/yunmiaohui/yunmiaohui/fengmiantu03.jpg"
                        src={"https://" + videoOrgin[this.videoIndex] + "/flash/mp4video63/TMS/2023/01/18/2309b61c882cc90bb6d08e90c7790e64_2000_h264_1872_aac_128.mp4"}></video>
                    <div className='loadingGamesPc_loading'>{videoMessage}</div>
                    <img className='loadingGamesPc_voice'
                        onClick={() => {
                            this.setState({
                                voiceStatus: !voiceStatus
                            })
                        }}
                        src={!voiceStatus ? 'https://img.alicdn.com/imgextra/i4/O1CN01CV2xk41YsG9bkbIx8_!!6000000003114-2-tps-82-95.png' : "https://img.alicdn.com/imgextra/i2/O1CN01aubbSZ205XGDYlwX5_!!6000000006798-2-tps-82-95.png"} />
                    {
                        showVideoLoading ? <div className='loadingGamesPc_videoLoading' >
                            <img src="https://img.alicdn.com/imgextra/i1/O1CN01p2X0Wc1WvnzPx3Qkp_!!6000000002851-2-tps-78-78.png" alt="" />
                            <div>视频加载中…</div>
                        </div> : null
                    }
                </div>
            }
            {
                modalStatus ? <Modal contentText={modalStatusContentText} closed={() => {
                    this.setState({ modalStatus: false })
                    if (modalBtnNeedBack) {
                        this.goBackPage()
                        // this.props.history.goBack()
                    }
                }} /> : null
            }
            {
                gamesToastPcStatus ? <GamesToastPc message={toastMessage} /> : null
            }
            {
                showSaveImg ? <div className='loadingGamesPc_saveimg_mask'>
                    <div className='loadingGamesPc_saveimg_box'>
                        <div className='loadingGamesPc_saveimg_title'>
                            <img src="https://img.alicdn.com/imgextra/i1/O1CN011g2lB41WAAne5vRpC_!!6000000002747-2-tps-28-28.png" alt="" />
                            <span>点击右键保存图片</span>
                        </div>
                        <img
                            onClick={() => {
                                this.setState({
                                    showSaveImg: false
                                })
                            }}
                            className='loadingGamesPc_saveimg_closed'
                            src="https://img.alicdn.com/imgextra/i3/O1CN01bzK3yy1d31vyIJMdj_!!6000000003679-2-tps-80-80.png" alt="" />
                        <img className='loadingGamesPc_saveimg_img' src={saveImgUrl} alt="" />
                    </div>
                </div> : null
            }
        </div>
    }
}
export default LoadingGamesPc;