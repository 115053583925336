import { geturlparam } from "./index"
let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

//这是必须要写的，用来创建一些设置
function setupWebViewJavascriptBridge(callback) {
    let sourceType = geturlparam().get("sourceType")
    if(sourceType!=="app"){
        return
    }
    //Android使用
    if (isAndroid) {
        console.log('window.WebViewJavascriptBridge',window.WebViewJavascriptBridge)
        if (window.WebViewJavascriptBridge) {
            callback(window.WebViewJavascriptBridge)
        } else {
            console.log('=======22222=========');
            document.addEventListener(
                'WebViewJavascriptBridgeReady',
                function () {
                    console.log('=======333333=========');
                    callback(window.WebViewJavascriptBridge)
                },
                false
            );
        }
        sessionStorage.phoneType = 'android'
    }

    //iOS使用
    if (isiOS) {
        if (window.WebViewJavascriptBridge) {
            return callback(window.WebViewJavascriptBridge);
        }
        if (window.WVJBCallbacks) {
            return window.WVJBCallbacks.push(callback);
        }
        window.WVJBCallbacks = [callback];
        var WVJBIframe = document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(function () {
            document.documentElement.removeChild(WVJBIframe)
        }, 0);
        sessionStorage.phoneType = 'ios'
    }
}
//注册回调函数，第一次连接时调用 初始化函数(android需要初始化,ios不用)
setupWebViewJavascriptBridge(function (bridge) {
    let sourceType = geturlparam().get("sourceType")
    if(sourceType!=="app"){
        return
    }
    if (isAndroid) {
        //初始化
        bridge.init(function (message, responseCallback) {
            var data = {
                'Javascript Responds': 'Wee!'
            };
            responseCallback(data);
        })
    }
})


export default {
    // js调APP方法 （参数分别为:app提供的方法名  传给app的数据  回调）
    callHandler(name, data, callback) {
        console.log('-----------callHandler');
        let sourceType = geturlparam().get("sourceType")
        if(sourceType==="app"){
            setupWebViewJavascriptBridge(function (bridge) {
                console.log('-----------setupWebViewJavascriptBridge',bridge);
                bridge.callHandler(name, data, callback)
            })
        }
    },
    // APP调js方法 （参数分别为:js提供的方法名  回调）
    registerHandler(name, callback) {
        let sourceType = geturlparam().get("sourceType")
        if(sourceType==="app"){
            setupWebViewJavascriptBridge(function (bridge) {
                bridge.registerHandler(name, function (data, responseCallback) {
                    callback(data, responseCallback)
                })
            })
        }
    }
}
