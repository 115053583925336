import React, { Component, createRef, Fragment } from 'react';
import axios from "axios";
import "./loginLoading.css";
import { geturlparam, isPC } from "../../tools/index";
import Toast from "./toast"
import { orgin } from '../../tools/config';
import Cookies from 'js-cookie'

const { v4 } = require('uuid');
let times = null
let interval1 = null
let intervals = null
class LoginLoading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            barNum: 0,
            loginStatus: true,
            toastMessage: "",       //错误信息文本
            toastIcon: false,
            showToastStatus: false,     //是否显示toast
            nickName: "",     // 用户名称
            unlockDate: ""   //解锁日
        };
        this.interval1Num = 0
    }

    componentDidMount() {
        const _this = this
        intervals = setInterval(() => {
            this.setState((v) => {
                if (v.barNum < 100) {
                    return {
                        barNum: v.barNum + _this.randowNum(v.barNum)
                    }
                } else {
                    clearInterval(intervals)
                }
            })
        }, 1000)
        this.dataCommit()
    }

    randowNum = (e) => {
        if (e < 75) {
            return Math.floor(Math.random() * 10)
        }
        if (e < 90) {
            return Math.floor(Math.random() * 5)
        }
        if (e < 99) {
            return Math.floor(Math.random() * 2)
        }
        return 0
    }

    dataCommit = () => {
        const { phoneNum, messageCode, back, history, requestFullscreens, mockStatus } = this.props

        let datas = {
            requestId: v4(),
            phoneNum: phoneNum,
            smsCode: mockStatus ? 8888 : messageCode,
            channel: "h5"
        }
        axios.post( orgin + "/login/commit", datas,{ timeout: 5000 }).then((res) => {
            console.log(444,res)
            if (res.data.success) {
                const { data = {} } = res.data
                if (data.status == "success") {
                    this.setState({
                        barNum: 100
                    })
                    let nickName = res.data.data.nickName
                    this.setState({
                        nickName
                    })
                    Cookies.set("token",res.data.data.session,{ expires: 15 })
                    // requestFullscreens()
                    setTimeout(() => {
                        history.replace(`/limbo`+window.location.search)
                    }, 2000)
                }
                if (data.status == "commit") {
                    this.getUderInfo()
                }
                if (data.status == "failed") {
                    this.showToast(res.data.errorMessage)
                    setTimeout(() => {
                        back()
                    }, 1500)
                }
                if (data.status == "uncommit") {
                    this.setState({
                        loginStatus: false
                    })
                    clearInterval(interval1)
                    clearInterval(intervals)
                }
            } else {
                this.showToast(res.data.errorMessage)
                clearInterval(interval1)
                clearInterval(intervals)
                setTimeout(() => {
                    back()
                }, 1500)
            }
        }).catch((res)=>{
            this.showToast("登录失败，请重试")
            clearInterval(interval1)
            clearInterval(intervals)
            setTimeout(() => {
                back()
            }, 1500)
        })
    }
    getdate = () => {
        let date = new Date()
        let y = date.getFullYear()
        let m = date.getMonth() + 1
        let d = date.getDate()
        return y + "." + m + "." + d
    }

    getUderInfo = () => {
        const { phoneNum, history, back, requestFullscreens } = this.props
        interval1 = setInterval(() => {
            console.log("轮循次数",this.interval1Num)
            this.interval1Num = this.interval1Num + 1
            if (this.interval1Num > 15) {
                clearInterval(interval1)
                clearInterval(intervals)
                this.showToast("登录失败，请重试")
                setTimeout(() => {
                    back()
                }, 1500)
                return
            }
            let data = {
                requestId: v4(),
                phoneNum: phoneNum,
            }
            axios.post(orgin+"/login/queryLoginRes", data).then((res) => {
                if (res.data.success && res.data.data && res.data.data.status == "success") {
                    clearInterval(interval1)
                    clearInterval(intervals)
                    this.setState({
                        barNum: 100
                    })
                    interval1 = null
                    let nickName = res.data.data.nickName
                    this.setState({
                        nickName
                    })
                    Cookies.set("token",res.data.data.session,{ expires: 15 })
                    // requestFullscreens()
                    setTimeout(() => {
                        history.replace(`/limbo`+window.location.search)
                    }, 2000)
                }
                if (res.data.data && res.data.data.status == "failed") {
                    clearInterval(interval1)
                    clearInterval(intervals)
                    this.showToast(res.data.errorMessage)
                    setTimeout(() => {
                        back()
                    }, 1500)
                }
            })
        }, 2000);

    }

    

    showToast = (e, success) => {
        if (!times) {
            this.setState({
                showToastStatus: true,
                toastMessage: e,
                toastIcon: success ? true : false
            })
            times = setTimeout(() => {
                this.setState({
                    showToastStatus: false,
                    toastMessage: "",
                    toastIcon: false
                })
                times = null
            }, 1500)
        }
    }

    navBack = () => {
        const { back } = this.props
        clearInterval(interval1)
        clearInterval(intervals)
        back()
    }

    render() {
        const { barNum, loginStatus, showToastStatus, toastMessage, toastIcon } = this.state
        const { back, sceneDesc } = this.props
        return <div className='loginLoadingPage' style={{ height: document.documentElement.clientHeight + "px" }}>
            {/* 导航栏 */}
            <div className='loginLoading_nav'>
                <img onClick={() => { this.navBack() }} src="https://img.alicdn.com/imgextra/i1/O1CN01uZ3pEd1OKgxuZfVZy_!!6000000001687-2-tps-70-70.png" alt="" />
                返回
            </div>
            {
                loginStatus ? <Fragment>
                    {/* 标题 */}
                    <div className='loginLoading_title'>
                        <div className='loginLoading_title_top'>{barNum >= 100 ? '登录成功' : '登录中'}</div>
                        <div className='loginLoading_title_bottom'>{barNum >= 100 ? '登录成功 您的央博宇宙身份已解锁，欢迎加入' : '正在解锁您的央博宇宙身份…'}</div>
                        <div className='loginLoading_title_bar'>
                            <img className='loginLoading_title_bar_imgBox' src="https://img.alicdn.com/imgextra/i3/O1CN01Ie5Kbm1RkkLSaMn11_!!6000000002150-2-tps-716-46.png" alt="" />
                            <div style={{ width: `${barNum * 0.9274 + 0.01}%` }} className='loginLoading_title_bar_bar'></div>
                            <img style={{ left: `${1 + barNum * 0.9}%` }} className='loginLoading_title_bar_imgHead' src="https://img.alicdn.com/imgextra/i3/O1CN01hVw3k21cVb16lLFA0_!!6000000003606-2-tps-66-70.png" alt="" />
                        </div>
                        <div className='loginLoading_title_tipsNum'>{barNum}%</div>
                    </div>
                    <div className='loginLoading_card'>
                        <img className='loginLoading_card_img' src={barNum >= 100 ? "https://img.alicdn.com/imgextra/i3/O1CN01xJgDz61q1HQimteCe_!!6000000005435-2-tps-1134-1460.png" : "https://img.alicdn.com/imgextra/i2/O1CN01Cojhwr1XlYkXaXtDn_!!6000000002964-2-tps-1134-1460.png"} alt="" />
                        {
                            barNum >= 100 ? <div className='loginLoading_userInfo'>
                                <div className='loginLoading_userInfo_top'>
                                    <span>{this.state.nickName}</span>
                                    <span>{sceneDesc || "时空万象"}</span>
                                </div>
                                <div className='loginLoading_userInfo_bottom'>
                                    <span>央博</span>
                                    <span>{this.getdate()}</span>
                                </div>
                            </div>
                                :
                                <img className='loginLoading_card_mask' src="https://img.alicdn.com/imgextra/i3/O1CN01NEogJT1ggpCGE59ze_!!6000000004172-2-tps-1226-1280.png" alt="" />
                        }
                    </div>
                </Fragment> : <Fragment>
                    <img className='loginLoading_fail_img' src="https://img.alicdn.com/imgextra/i2/O1CN01mfvqIJ1LLexhfDEuk_!!6000000001283-2-tps-628-700.png" alt="" />
                    <div className='loginLoading_fail_title'>抱歉，央博宇宙当前人气爆棚</div>
                    <div className='loginLoading_fail_tips' >
                        <span>请下载央博App体验，通过App体验减少排队进程，享受流畅体验</span>
                        <img onClick={() => { window.location.href = 'https://sj.qq.com/appdetail/com.cctv.apps.museum' }} src="https://img.alicdn.com/imgextra/i2/O1CN0103TsBu1v9aghh2aWQ_!!6000000006130-2-tps-768-208.png" alt="" />
                    </div>
                </Fragment>
            }
            {
                showToastStatus ? <Toast message={toastMessage} toastIcon={toastIcon} /> : null
            }
        </div>
    }
}
export default LoginLoading;