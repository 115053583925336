import './App.css';
import React, { Component, Fragment, createRef } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import routeArr from './route';
import VConsole from 'vconsole';
import { isPC } from "./tools/index";
// const vConsole = new VConsole();

class App extends Component {

  constructor(props) {
    super(props);
  }
  randerRoute = () => {
    let routers = routeArr.map((v) => {
      return <Route key={v.name} exact path={v.name} component={v.component} />
    });
    return (
      <>
        {routers}
        {
          isPC() ? <Route exact path="/" render={() => <Redirect to={'/homePc' + window.location.search} />} /> :
            <Route exact path="/" render={() => <Redirect to={'/home' + window.location.search} />} />
        }
      </>
    )
  }

  render() {
    return <Switch>
      {
        this.randerRoute()
      }
    </Switch>
  }
}
export default App;