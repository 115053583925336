import React, { Fragment} from "react";
import "./index.css"

function BackButtonPc({
    text="返回",
    onClick=()=>null
}) {
    return <div className="backButtonPc" onClick={ ()=>{ onClick() } }>
        { text }
    </div>
}

export default BackButtonPc;