import React, { Fragment } from "react";
import "./index.css"

function ModalPc({
    title = "温馨提示",
    contentText="抱歉，请稍后访问",
    closed = () => null
}) {
    return <div className="modalPc_mask">
        <div className="modalPc_box">
            <div className="modalPc_title">{title?title:"温馨提示"}</div>
            <div className="modalPc_contents">{contentText?contentText:"抱歉，请稍后访问"}</div>
            <div className="modalPc_btn" onClick={()=>{ closed() }}>知道了</div>
        </div>
    </div>

}

export default ModalPc;