import React, { Component, Fragment, useState, useEffect } from 'react';
import "./index.css"

function ToastPc({message,toastIcon}){
    const [ tips, setTips ] = useState(message)
    const [ icon, setIcon ] = useState(toastIcon)
    useEffect(()=>{
        setTips(message)
        setIcon(toastIcon)
    },[message])
    return <Fragment>
    <div className='toastPc_page'>
    <img src={ icon ? "https://img.alicdn.com/imgextra/i4/O1CN01r4vRVq1xh8w7M25br_!!6000000006474-2-tps-40-40.png":"https://img.alicdn.com/imgextra/i3/O1CN017bGJbr1TPnShkCpF6_!!6000000002375-2-tps-40-40.png" } alt="" />
        <span>{tips}</span>
    </div>
 </Fragment>
}
export default ToastPc;