let currentTime = 0
let videoDom = ""
let backFunc = ""
let usedBackFuncStatus = false
let videoPlayEnd = ""

// doms:video的dom，func:开始播放的回调，playFailed:z自动播放失败的回调

function videoAddEventListener(doms,func,playFailed,playEnd){
    usedBackFuncStatus=false
    currentTime = 0
    videoDom = doms
    backFunc = func
    videoPlayEnd = playEnd
    doms.addEventListener('play', videoPlay);
    doms.addEventListener('pause', videoPause);
    doms.addEventListener('timeupdate', videoTimeupdate, false)
    doms.addEventListener('error', videoError)
    doms.addEventListener('stalled', videoStalled)
    if(playFailed){
        doms.play().then((res)=>{
        
        }).catch((err)=>{
            console.log("视频播放失败===>",err)
            playFailed&&playFailed()
        })
    }
    
    // doms.addEventListener("loadedmetadata",videoLoadedmetadata)
}

function videoStalled(){
    console.log("视频资源不支持")
    videoDom.scr = videoDom.src
}

function videoError(e){
    videoDom.scr = videoDom.src
    console.log("视频出错了")
}

function videoPlay (){
    
}


function videoTimeupdate(){
    currentTime = videoDom.currentTime
    if(currentTime-0>0 && !usedBackFuncStatus){
        backFunc && backFunc()
        usedBackFuncStatus = true
    }
    if(videoDom.currentTime-0+0.5>videoDom.duration){
        videoPlayEnd&&videoPlayEnd()
    }
}

function videoPause(){
    if(currentTime-0+1<videoDom.duration-0){
        videoDom.play()
        videoDom.currentTime = currentTime
    }   
}

function videoRemoveEventListener(doms){
    doms.removeEventListener('play', videoPlay);
    doms.removeEventListener('pause', videoPause);
    doms.removeEventListener('timeupdate', videoTimeupdate, false)
    doms.removeEventListener('error', videoPause);
    doms.removeEventListener('stalled', videoPause);
    usedBackFuncStatus = false
    currentTime = 0
}
export { videoAddEventListener, videoRemoveEventListener }
