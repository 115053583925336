import React, { Component } from 'react'
import { geturlparam, initSource } from "../../tools/index";
import './index.css'
import axios, { Axios } from "axios";
import { orgin } from '../../tools/config';
export default class Limbo extends Component {
  state = {
    msg2: "即将帮您切换横屏体验模式",
    source: {}
  }
  componentDidMount() {
    const { history } = this.props
    let sourceType = geturlparam().get("sourceType")
    initSource((source) => {
      if (source) {
        document.documentElement.style.setProperty('--background', `url("${source.background}")`)
        this.setState({ source })
      }
    })
    setTimeout(() => {
      if (sourceType === "app") {
        const datas = {
          swKey: "is-show-video",
          swType: "video",
        }
        axios.post(orgin + "/meta-switch/list", {}).then((res) => {
          if (res.status == 200 && res.data.data && res.data.data[0] && res.data.data[0].swValue === "true") {
            history.replace(`/introduce` + window.location.search)
          } else {
            history.replace(`/lineup` + window.location.search)
          }
        }).catch((err) => {
          history.replace(`/introduce` + window.location.search)
        })
      } else {
        history.replace(`/lineup` + window.location.search)
      }
    }, 2000)
  }
  render() {
    const { source, msg2 } = this.state;
    return (
      <div className="limbo_bg" style={{ height: document.documentElement.clientHeight + "px" }}>
        <img className="limbo_ybLogo" src={source.logo} alt="" />
        <div className='limbo_mask'>
          <img className="rate_btn" src="https://app-yangbo.cctv.com/yunmiaohui/yunmiaohui/tixing.gif" alt="" />
          <div className="limbo_title1">{source.text1}</div>
          <div className="limbo_title2">{msg2}</div>
        </div>
      </div>
    )
  }
}
