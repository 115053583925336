import React, { Fragment, useState, useEffect } from 'react';
import "./toast.css"

function Toast({message, toastIcon }){
    const [ tips, setTips ] = useState(message)
    const [ icon, setIcon ] = useState(toastIcon)
    useEffect(()=>{
        setTips(message)
        setIcon(toastIcon)
    },[message,toastIcon])
    return  <div className='toast_box'>
        <img src={ icon ? "https://img.alicdn.com/imgextra/i3/O1CN01KUgVrV1thp3ov4646_!!6000000005934-2-tps-20-20.png":"https://img.alicdn.com/imgextra/i1/O1CN015OtUvr1yNHMwobpjm_!!6000000006566-2-tps-70-70.png" } alt="" />
        <span>{tips}</span>
    </div>
}
export default Toast;