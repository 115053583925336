import Login from "./pages/login/index";
import Home from "./pages/home/index";
import Lineup from "./pages/lineup/index";
import LoadingGames from "./pages/loadingGames";
import LoginPc from "./pages/loginPc/index";
import LineupPc from "./pages/lineupPc/index";
import LoadingGamesPc from "./pages/loadingGamesPc";
import Introduce from "./pages/introduce";
import HomePc from "./pages/homePc";
import IntroducePc from "./pages/introducePc"
import Limbo from './pages/limbo'

const routes = [
    {
        name: "/home",
        component: Home
    },{
        name: "/homePc",
        component: HomePc
    },{
        name: "/introduce",
        component: Introduce
    },{
        name: "/introducePc",
        component: IntroducePc
    },
    {
        name: "/login",
        component: Login
    }
    , {
        name: "/lineup",
        component: Lineup
    } ,{
        name:'/loadingGames',
        component: LoadingGames
    },{
        name:'/loginPc',
        component: LoginPc
    },{
        name:"/lineupPc",
        component: LineupPc
    },{
        name:"/loadingGamesPc",
        component: LoadingGamesPc
    },{
        name: "/limbo",
        component: Limbo
    },
]
export default routes;