import React, { Fragment } from "react";
import "./modal.css"

function Modal({
    title = "温馨提示",
    contentText = "",
    closed = () => null
}) {
    return <div className="loadingGames_modal_mask">
        <div className="loadingGames_modal_box">
            <div className="loadingGames_modal_title">{title}</div>
            <div className="loadingGames_modal_contents">{contentText}</div>
            <div className="loadingGames_modal_btn" onClick={()=>{ closed() }}>知道了</div>
        </div>
    </div>

}

export default Modal;