import React, { } from "react";
import "./index.css";
import ModalPc from "../../component/ModalPc"
import { isPC, initSource } from "../../tools/index";
import axios, { Axios } from "axios";
import { orgin } from '../../tools/config';

let gcs = window.gcsObj
class HomePc extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sourceType: "app",
            showModal: false,
            supportStatus: true,
            hideAliLogo: false,
            btnClickStatus: false,
            noticeStatus: false,    // 公告弹框状态
            noticeData: {}, // 公告内容数据
            noticeResult: false, // 查询公告接口是否有返回
            source: {}
        }
    }

    componentDidMount() {
        if (!isPC()) {
            this.props.history.replace("/home" + window.location.search)
        } else {
            this.loadingGcs()
            this.setState({
                hideAliLogo: sessionStorage.getItem("ybAliLogo") ? true : false
            }, () => {
                sessionStorage.setItem("ybAliLogo", true)
                setTimeout(() => {
                    this.setState({
                        hideAliLogo: true
                    })
                }, 2000)
            })
            initSource((source) => {
                if (source) {
                    this.setState({ source })
                }
            })
        }
    }

    getGameStatus = () => {
        const data = {
            swKey: "deploy-notice",
            swType: "notice",
            "noticeId": "home"
        }
        axios.post(orgin + "/announcement/list", data).then((res) => {
            console.log(res)
            if (res.status == 200) {
                let data = res.data.data || []
                let obj = data[0] || {}
                this.setState({
                    noticeStatus: obj.status ? true : false,
                    noticeData: obj,
                })
            }
        }).finally(() => {
            this.setState({
                noticeResult: true
            })
        })
    }

    loadingGcs = () => {
        const _this = this
        gcs.isSupport().then(res => {
            console.log("浏览器支持")
            _this.getGameStatus()
            // 可用
        }).catch(err => {
            console.log('GCSSDK not support: ', err)
            _this.setState({
                showModal: true,
                supportStatus: false
            })
            // { type: '10', code: '109010', message: '当前浏览器不支持' }
            // { type: '10', code: '109011', message: '浏览器版本过低' }
            // { type: '10', code: '109012', message: '不支持webrtc' }
            // { type: '10', code: '109013', message: '不支持H264' }
        }).finally((respons) => {
            console.log("isSupport==>finally")
            this.setState({
                btnClickStatus: true
            })
        })
    }

    modalClick = () => {
        this.setState({
            showModal: false
        })
    }

    toNextPage = () => {
        const { supportStatus, btnClickStatus, noticeResult } = this.state
        const { history } = this.props
        if (!btnClickStatus) {
            return
        }
        if (!supportStatus) {
            this.setState({
                showModal: true
            })
            return
        }
        if (!noticeResult) {
            return
        }
        const data = {
            swKey: "deploy-notice",
            swType: "notice",
            "noticeId": "home"
        }
        const datas = {
            swKey: "is-show-video",
            swType: "video",
        }
        let pro1 = axios.post(orgin + "/announcement/list", data)
        let pro2 = axios.post(orgin + "/meta-switch/list", datas)
        Promise.all([pro1, pro2]).then(([res1, res2]) => {

            if(res1.status == 200){
                let data = res1.data.data || []
                let obj = data[0] || {}
                let preRelease = obj.preRelease ? 1 : 0
                if(preRelease == 1 && obj.status==1){
                    this.setState({
                        noticeStatus: true,
                        noticeData: obj,
                    })
                    return
                }
            }
            
            if (res2.status == 200 && res2.data.data && res2.data.data[0] && res2.data.data[0].swValue === "true") {
                history.push("/introducePc" + window.location.search)
            } else {
                history.push("/loginPc" + window.location.search)
            }
        }).catch((err) => {
            history.push("/introducePc" + window.location.search)
        })
    }
    render() {
        const { showModal, hideAliLogo, noticeStatus, noticeData, source } = this.state
        const { title, content } = noticeData

        return <div className="homePcPage" 
        // style={{ background: `url(${source.background}) no-repeat center top` }}
        >
            {/* <div className="homePc_position_btn" onClick={() => { this.toNextPage() }}></div> */}
            <img className="homePc_background" src={source.background} alt="" />
            <img className="homePc_btns" onClick={() => { this.toNextPage() }} src={source.button} alt="" />
            <img className="homePc_ybLogo" src={source.logo} alt="" />
            {
                showModal ? <ModalPc closed={() => { this.modalClick() }} /> : null
            }
            {
                noticeStatus ? <ModalPc title={title} contentText={content} closed={() => { this.setState({ noticeStatus: false }) }} /> : null
            }
            {
                hideAliLogo ? null : <div className="homePc_ali">阿里云提供计算服务</div>
            }
        </div>
    }
}

export default HomePc;