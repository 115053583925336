import React, { Component, Fragment, createRef } from 'react';
import "./index.css"
import { isPC, geturlparam, initSource, getRttObj, getCdnUrl } from "../../tools/index";
import axios from 'axios';
import { getlineupRequestHeaders } from "../../tools/lineRequest"
import BackButtonPc from '../../component/BackButtonPc/index';
import { videoAddEventListener, videoRemoveEventListener } from "../../tools/videoEventListene"
import ModalPc from '../../component/ModalPc';
import { orgin } from '../../tools/config';
import { videoOrgin, lineupOrgin } from "../../tools/config"
import GamesToastPc from "./toast"
import Cookies from 'js-cookie'

const { v4 } = require('uuid');

class LineupPc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gcsReady: false,
            lineupNum: "----",
            lineupTitle: "正在排队，请等待",
            videoMessage: "正在进入…",
            lineupSuccess: false,
            needLineup: false,
            showVideoLoading: true,
            videoStartPlayStatus: true, // 是否显示开始播放按钮
            voiceStatus: false,
            orderModalStatus: false,
            modalTitle: "",
            modalContentText: "",
            modalStatus: false,
            modalStatusContentText: "",
            modalBtnNeedBack: true,
            gamesToastPcStatus: false,
            toastMessage: "",
            showSaveImg: false,
            saveImgUrl: "",
            lineupBar: 0, //进度条
            videoPlayEnd: false, // 视频播放是否结束
            userInfo: {},
            sceneId: "2",
            source: {},
            gamesRtt: 30
        };
        this.videoRef = createRef()
        this.interval = ""
        this.setTimeouts = ""
        this.videoIndex = Math.floor(Math.random() * 2)
        this.barInterval = ""
        this.MeetSDKs = window.MeetSDKs
        this.meetSDK = ''
        this.loginFaild = ""
    }
    componentDidMount() {
        if (!isPC()) {
            this.props.history.replace("/home" + window.location.search)
        } else {
            document.querySelector("body").style.background = "#000"
            videoAddEventListener(document.querySelector(".lineupPc_video"), this.videoPlay, undefined, this.playEnd)
            // 监听窗口关闭
            this.listenWindowClose()
            // 进度条
            this.startLineupBar()
            // 获取用户信息
            this.getUserInfo()
            initSource((source) => {
                if (source) {
                    if(source.vdnUrl){
                        getCdnUrl(source.vdnUrl,(obj)=>{
                            this.setState({
                                source: obj
                            })
                        })
                        return
                    }
                    this.setState({ source })
                }
            })
        }
    }

    getUserInfo = () => {
        // let listData = {
        //     "swType": "scenario",
        //     "swKey": "scenarioId"
        // }
        // let pro1 = axios.post(orgin + "/meta-switch/list", listData)
        let sceneId = geturlparam().get("sceneId") || "undefined"
        let pro1 = axios.post(orgin + `/scenario/route`, { sceneId: sceneId })

        let pro2 = axios({
            method: 'post',
            url: orgin + "/login/getInfoByCookie",
            data: { requestId: v4(), isGetInfo: true },
            headers: {
                'token': Cookies.get('token')
            }
        })
        Promise.all([pro1, pro2]).then(([res1, res2]) => {
            sceneId = "2"
            if (res1.status == 200) {
                const { data } = res1.data
                sceneId = data.sceneId
            }

            if (res2.status == 200 && !res2.data.success) {
                this.showToast("登录失效，请重新登录！")
                Cookies.remove('token')
                // this.loginFaild = setTimeout(() => {
                //     this.props.history.replace("/loginPc" + window.location.search)
                // }, 1500)
                return false
            }
            if (res2.status == 200) {
                this.setState({
                    userInfo: res2.data.data,
                    sceneId
                }, () => {
                    this.lineup()
                })
            }
        }).catch(() => {
            this.showToast("登录失效，请重新登录！")
        })
    }

    playEnd = () => {
        console.log("视频播放结束")
        const { gcsReady } = this.state
        if (gcsReady) {
            try {
                videoRemoveEventListener(document.querySelector(".lineupPc_video"))
            } catch (err) { }
            this.setState({
                voiceStatus: true
            }, () => {
                this.setState({
                    videoPlayEnd: true
                })
            })
        } else {
            this.setState({
                videoPlayEnd: true
            })
        }

    }

    startLineupBar = () => {
        const _this = this
        this.barInterval = setInterval(() => {
            this.setState((v) => {
                if (v.lineupBar < 100) {
                    return {
                        lineupBar: v.lineupBar + _this.randowNum(v.lineupBar, v.lineupSuccess)
                    }
                } else {
                    clearInterval(this.barInterval)
                }
            })
        }, 1000)
    }

    randowNum = (e, lineupSuccess) => {
        if (lineupSuccess) {
            if (e < 75) {
                return Math.floor(Math.random() * 10)
            }
            if (e < 90) {
                return Math.floor(Math.random() * 5)
            }
            if (e < 99) {
                return Math.floor(Math.random() * 2)
            }
            return 0
        } else {
            if (e < 60) {
                return Math.floor(Math.random() * 10)
            }
            if (e < 70) {
                return Math.floor(Math.random() * 5)
            }
            if (e < 79) {
                return Math.floor(Math.random() * 2)
            }
            return 0
        }
    }


    listenWindowClose = () => {
        let _beforeUnload_time = 0, _unload_time = 0
        window.addEventListener('beforeunload', () => {
            _beforeUnload_time = new Date().getTime()

        })
        window.addEventListener('unload', () => {
            _unload_time = new Date().getTime()
            if (_unload_time - _beforeUnload_time <= 1) {
                // 关闭浏览器执行的方法
                console.log("浏览器关闭")
                try {
                    console.log("页面刷新，调用stop");
                    this.meetSDK.stop()
                    document.querySelector("#renderGcsDom").innerHTML = ""
                    document.querySelector("#renderGcsDom").style.width = 0
                    this.meetSDK = null
                } catch (err) { }

            } else {

            }

        })
    }

    playFailed = () => {
        this.setState({
            voiceStatus: true
        }, () => {
            document.querySelector(".lineupPc_video").play()
        })
    }

    videoPlay = () => {
        this.setState({
            showVideoLoading: false
        })
    }

    lineup = () => {
        const { history } = this.props
        const { sceneId, userInfo } = this.state
        const { nickName, userId } = userInfo || {}
        const _this = this
        this.interval = setInterval(() => {
            const data = {
                nickname: nickName,
                sceneId: sceneId,
                source: 'pc',
                uid: userId,
                userType: geturlparam().get("userType") && !isNaN(Number(geturlparam().get("userType"))) ? geturlparam().get("userType") - 0 : 0
            }
            let headers = getlineupRequestHeaders(data, "/queue/enter")
            axios({
                method: "post",
                url: lineupOrgin + '/queue/enter',
                headers: headers,
                data: data
            }).then((res) => {
                if (res.data.success) {
                    if (res.data.result.isQueue) {
                        _this.setState({
                            lineupNum: res.data.result.index,
                            needLineup: true
                        })
                    } else {
                        _this.setState({
                            lineupNum: res.data.result.index
                        })
                        this.setTimeouts = setTimeout(() => {
                            _this.setState({
                                lineupSuccess: true,
                                needLineup: false
                            })
                        }, 1000)
                        setTimeout(() => {
                            _this.setState({
                                videoMessage: "央博宇宙资源准备中…"
                            })
                        }, 3000)

                        clearInterval(this.interval)
                        this.initializationGcs()
                    }
                } else {
                    clearInterval(this.interval)
                }
            })
        }, 2000)
    }

    componentWillUnmount() {
        if (window.location.reload) {
            window.location.reload()
            return
        }
        document.querySelector("body").style.background = "transparent"
        clearInterval(this.barInterval)
        try {
            this.meetSDK.setVolume(0)
        } catch (err) { }
        try {
            console.log("调用stopStyle")
            this.meetSDK.stopStyle()
            document.querySelector("#renderGcsDom").innerHTML = ""
            document.querySelector("#renderGcsDom").style.width = 0
            document.querySelector("#renderGcsDom").style.height = 0
        } catch (err) { }
        try {
            videoRemoveEventListener(document.querySelector(".lineupPc_video"))
            // 执行暂停事件
            document.querySelector(".lineupPc_video").pause()
        } catch (err) { }
        clearInterval(this.interval)
        clearTimeout(this.setTimeouts)
        clearTimeout(this.loginFaild)
    }

    clickOrder = () => {
        const { userInfo } = this.state
        const { userId, mobile } = userInfo
        let data = {
            requestId: v4(),
            userid: userId,
            phoneNum: mobile
        }

        axios.post(orgin + "/user/reservation", data).then((res) => {
            console.log("点击预约====>", res)
            if (res.data.success) {
                this.setState({
                    modalTitle: "预约成功",
                    modalContentText: "央博宇宙会在不排队时，短信通知您，建议您闲时再来～",
                    orderModalStatus: true
                })
            } else {
                this.setState({
                    modalTitle: "温馨提示",
                    modalContentText: res.data.errorMessage,
                    orderModalStatus: true
                })
            }
        })

    }


    initializationGcs = (e) => {
        const { sceneId, userInfo } = this.state
        const { nickName, userId, mobile, sign } = userInfo
        const _this = this
        const config = {
            uid: userId,
            nickname: nickName,
            source: 'pc',
            sceneId: sceneId,
            userType: geturlparam().get("userType") && !isNaN(Number(geturlparam().get("userType"))) ? geturlparam().get("userType") - 0 : 0,
            tagId: "renderGcsDom"
        }
        if (mobile) {
            config.mobile = mobile
        }
        if (sign) {
            config.sign = sign
        }
        console.log("MeetSDKsConfig======>", config)
        this.meetSDK = new this.MeetSDKs(config)
        this.meetSDK.init().then((res) => {
            if (res) {
                this.meetSDK.start()
            }
        })
        this.meetSDK.on('MeetEvent', res => {
            const { type, message } = res;
            // console.log("sdk=====>", res)
            switch (type) {
                case 'ready': //        
                    _this.setState({
                        videoMessage: "准备完成，立即体验",
                        lineupBar: 100
                    })
                    // this.meetSDK.setVolume(1);//ios   
                    if (this.state.videoPlayEnd) {
                        try {
                            videoRemoveEventListener(document.querySelector(".lineupPc_video"))
                        } catch (err) { }
                        _this.setState({
                            voiceStatus: true
                        })

                    }
                    setTimeout(() => {
                        _this.setState({
                            gcsReady: true,
                        })
                    }, 1000)
                    break;
                case 'save': //  
                    _this.saveImg(message)
                    console.log(message); //      
                    break;
                case 'share': //  
                    _this.shareImg()
                    console.log(message); //     
                    break;
                case 'exit': // 
                    _this.goBackPage()
                    console.log(message); //        
                    break;
                case 'error': //

                    _this.gcsError(message)
                    _this.saveErrorLog({ nickName, userId, mobile, userAgent: navigator.userAgent, type: "PC", ...message })
                    console.log(message); //       
                    break;
                case 'info':
                    const { code, desc = {} } = message
                    const { rtt = 30 } = desc
                    if (code == "401020") {
                        this.setState({
                            gamesRtt: rtt
                        })
                    }
                    if (code == "904020") {
                        this.showToast("重连成功")
                    }
                    break;
                default:
                    break;

            }
        })
    }

    // 把错误日志保存在后端
    saveErrorLog = (e) => {
        axios.post(orgin + "/logger/write", { action: "gcsError", message: JSON.stringify(e) })
    }

    // 分享图片
    shareImg = () => {
        this.showToast("该功能仅在央博App内执行")
    }
    // 保存图片
    saveImg = (obj) => {
        this.setState({
            showSaveImg: true,
            saveImgUrl: obj.url
        })
    }

    goBackPage = () => {
        if (!window.location.pathname.includes("home")) {
            try {
                this.meetSDK.setVolume(0)
            } catch (err) { }
            try {
                console.log("调用stopStyle")
                this.meetSDK.stopStyle()
            } catch (err) { }
            console.log("当前页面====>", window.location.pathname)
            this.meetSDK = null
            document.querySelector("#renderGcsDom").innerHTML = ""
            document.querySelector("#renderGcsDom").style.width = 0
            document.querySelector("#renderGcsDom").style.height = 0
            this.props.history.goBack()
        }

    }

    gcsError = (message) => {
        const { desc, code } = message
        console.log("显示错误", desc, code)
        if (code == "501030" || code == "501041" || code == "904010" || code == "904020" || code == "904030") {
            this.showToast(desc)
            return
        }
        if (code == "903010" || code == "903020" || code == "510002" || code == "603010" || code == "501040") {
            this.setState({
                modalStatus: true,
                modalStatusContentText: desc,
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "901080") {
            this.setState({
                modalStatus: true,
                modalStatusContentText: "欢迎继续体验~",
                modalBtnNeedBack: false
            })
            return
        }
        if (code == "510001" || code == "501053") {
            this.setState({
                modalStatus: true,
                modalStatusContentText: "会话停止中，请1分钟以后再试",
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "510003") {
            this.setState({
                modalStatus: true,
                modalStatusContentText: "非正常请求！",
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "904030") {
            this.setState({
                modalStatus: true,
                modalStatusContentText: "重连失败，请1分钟以后再试",
                modalBtnNeedBack: true
            })
            return
        }
        this.showToast("服务器异常，请稍后重试")
    }

    showToast = (e) => {
        if (!this.showToastTime) {
            this.setState({
                gamesToastPcStatus: true,
                toastMessage: e,
            })
            this.showToastTime = setTimeout(() => {
                this.setState({
                    gamesToastPcStatus: false,
                    toastMessage: "",
                })
                this.showToastTime = null
            }, 3000)
        }
    }

    render() {
        const {
            lineupSuccess, needLineup, lineupNum, showVideoLoading,
            voiceStatus, orderModalStatus, modalTitle, modalContentText,
            gcsReady, videoMessage, modalStatus, modalStatusContentText, modalBtnNeedBack,
            gamesToastPcStatus, toastMessage, showSaveImg, saveImgUrl, lineupBar, videoPlayEnd,
            source, gamesRtt, videoStartPlayStatus
        } = this.state
        return <div className='lineupPc_page' style={{ zIndex: 1000 }} >
            {
                (!gcsReady || !videoPlayEnd) ? <div style={{ zIndex: (!gcsReady || !videoPlayEnd) ? 1000 : 0, width: "100%", height: "100vh" }}>
                    <video className="lineupPc_video"
                        ref={this.videoRef}
                        poster={source.poster}
                        autoPlay={false}
                        muted={voiceStatus}
                        loop
                        src={source.video}></video>
                    {
                        showVideoLoading ? <div className='lineupPc_videoLoading'
                            onClick={() => {
                                this.setState({
                                    videoStartPlayStatus: false
                                }, () => {
                                    document.querySelector(".lineupPc_video").play()
                                })
                            }}
                        >
                            <img className={`${videoStartPlayStatus ? "lineupPc_video_img_play" : "lineupPc_video_img_loading"}`} src={`${videoStartPlayStatus ? "https://img.alicdn.com/imgextra/i3/O1CN01u8uIMq1lUWdG35xpO_!!6000000004822-2-tps-44-46.png" : "https://img.alicdn.com/imgextra/i1/O1CN01p2X0Wc1WvnzPx3Qkp_!!6000000002851-2-tps-78-78.png"}`} alt="" />
                            <div>{videoStartPlayStatus ? "点击播放" : "视频加载中…"}</div>
                        </div> : null
                    }
                    <div className='lineupPc_detail'>
                        {
                            !needLineup ? <div className='lineupPc_detail_into'>
                                {videoMessage}
                            </div> : <Fragment>
                                <div className='lingupPc_desc'>
                                    <div>正在排队，请等待…</div>
                                    <div>如果出现断网、刷新页面等情况，需要重新排队哦</div>
                                </div>
                                <br />
                                <span className='lineupPc_position'>当前位置：<span>{lineupNum}</span></span>
                            </Fragment>
                        }

                    </div>

                    <BackButtonPc onClick={() => { this.props.history.goBack() }} />
                    <div className='lineupPc_bottom_mask'>
                        <div className='lineupPc_bar'>
                            <div className='lineupPc_bar_box'>
                                <div className='lineupPc_bar_content' style={{ width: `${lineupBar}%` }}></div>
                            </div>
                            <div className='lineupPc_bar_num'>加载中… {lineupBar}%</div>
                        </div>
                        <img className='lingupPc_voice'
                            onClick={() => {
                                this.setState({
                                    voiceStatus: !voiceStatus
                                })
                            }}
                            src={!voiceStatus ? 'https://img.alicdn.com/imgextra/i3/O1CN01A1vYs91LDPpzNsO29_!!6000000001265-2-tps-96-96.png' : "https://img.alicdn.com/imgextra/i1/O1CN01R1LgLz1Zt0QH5HAoV_!!6000000003251-2-tps-96-96.png"} />
                        {
                            needLineup ? <div className='lineupPc_order' onClick={() => { this.clickOrder() }} ></div> : null
                        }
                        {
                            !needLineup && gcsReady
                                ? <div className='lineupPc_skip' onClick={() => { this.playEnd() }}>跳过</div> : null
                        }
                    </div>
                    {
                        orderModalStatus ? <ModalPc title={modalTitle} contentText={modalContentText} closed={() => { this.setState({ orderModalStatus: false }) }} /> : null
                    }
                </div> : <div className='lineupPc_rtt_box' style={{ color: getRttObj(gamesRtt).color }}>
                    <img src={getRttObj(gamesRtt).url} alt="" style={{ width: "16px", height: "16px" }} />
                    <span style={{ paddingLeft: "4px", paddingRight: "4px" }}>{gamesRtt}</span>
                    <span>ms</span>
                </div>
            }
            {
                modalStatus ? <ModalPc contentText={modalStatusContentText} closed={() => {
                    this.setState({ modalStatus: false })
                    if (modalBtnNeedBack) {
                        this.goBackPage()
                        // this.props.history.goBack()
                    }
                }} /> : null
            }
            {
                gamesToastPcStatus ? <GamesToastPc message={toastMessage} /> : null
            }
            {
                showSaveImg ? <div className='loadingGamesPc_saveimg_mask'>
                    <div className='loadingGamesPc_saveimg_box'>
                        <div className='loadingGamesPc_saveimg_title'>
                            <img src="https://img.alicdn.com/imgextra/i1/O1CN011g2lB41WAAne5vRpC_!!6000000002747-2-tps-28-28.png" alt="" />
                            <span>点击右键保存图片</span>
                        </div>
                        <img
                            onClick={() => {
                                this.setState({
                                    showSaveImg: false
                                })
                            }}
                            className='loadingGamesPc_saveimg_closed'
                            src="https://img.alicdn.com/imgextra/i2/O1CN01HpbJyb1QVLPvRI0IL_!!6000000001981-2-tps-80-80.png" alt="" />
                        <img className='loadingGamesPc_saveimg_img' src={saveImgUrl} alt="" />
                    </div>
                </div> : null
            }

        </div>
    }
}
export default LineupPc;