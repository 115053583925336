import React, { Component, createRef, Fragment } from 'react';
import axios, { Axios } from "axios";
import "./index.css";
import { isPC, rotateScreen, geturlparam, isIos, initSource, getRttObj, getCdnUrl } from "../../tools/index";
import BackButton from '../../component/BackButton';
import { getlineupRequestHeaders } from "../../tools/lineRequest"
import jsbridges from "../../../src/tools/jsbridge.js"
import { videoAddEventListener, videoRemoveEventListener } from "../../tools/videoEventListene"
import ModalMobile from "../../component/ModalMobile/index"
import { orgin, videoOrgin, lineupOrgin } from '../../tools/config';
import Modal from "./modal"
import Toast from './toast';
import { data } from 'browserslist';
import Cookies from 'js-cookie'

const { v4 } = require('uuid');

var evt = "onorientationchange" in window ? "orientationchange" : "resize";

class Lineup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gcsReady: false,
            luneupNum: "--",    // 当前的排队位置
            lineupTitle: "正在排队，请等待",
            videoMessage: "正在进入…",
            sourceType: "",
            lineupSuccess: false,
            needLineup: false,
            voiceStatus: false, // video的静音状态
            showVideoLoading: true, // 是否显示视频的loading
            videoPlayStatus: "failed", // 视频播放的状态 loading:加载中，failed:自动播放失败
            modalStatus: false, // 预约的弹框
            modalTitle: "", // 预约的弹框的标题
            modalContentText1: "",  // 预约的弹框的内容
            fullscreenStatus: true,
            // app测的用户信息
            appPhoneNum: null, // 手机号
            appUid: "",    // 用户id
            nickname: "",   // 用户昵称

            clickSaveImgStatus: false,  // 若是扫码情况下，UE点击保存按钮，展示在我们的页面上
            saveImgUrl: "", // 若是扫码情况下，UE点击保存按钮，需要保存的图片url
            showModal: false,   // 显示弹框
            modalContentText: "",   //弹框内容
            modalBtnNeedBack: true,    // 弹框按钮点击按钮，是否返回上一页
            showToast: false,    // 轻提示
            toastText: "",  //轻提示内容
            rotateStatus: true,     //屏幕的旋转状态，竖屏为TRUE，横屏为false
            lineupBar: 0, //进度条
            videoPlayEnd: false, // 视频播放是否结束
            userInfo: {}, // 用户信息
            sceneId: "1", // 场景id，默认是1，时空万象
            source: {},
            gamesRtt: 0
        };
        this.lineupRef = createRef()
        this.interval = ""
        this.timeouts = ""
        this.videoIndex = Math.floor(Math.random() * 2)
        this.barInterval = ""
        this.MeetSDKs = window.MeetSDKs
        this.meetSDK = ''
    }
    componentDidMount() {
        if (isPC()) {
            this.props.history.replace("/homePc" + window.location.search)
        } else {
            // 注册jsbridges的方法
            try {
                jsbridges.registerHandler("yangboWebMethod", (responseData) => {
                    console.log("shareImg接收参数的方法", responseData)
                })
            } catch (err) { }
            // 旋转
            rotateScreen(this.lineupRef, evt, this.getFullListen)
            // 获取h5来源
            this.setState({
                sourceType: geturlparam().get("sourceType")
            })
            // 给视频添加监听事件
            videoAddEventListener(document.querySelector("#lineupVideo"), this.videoPlay, undefined, this.playEnd)

            // 监听浏览器关闭
            this.listenWindowClose()

            // 监听全屏事件
            this.fullListen()
            const fullscreenEvts = {
                'requestFullscreen': 'fullscreenchange',
                'webkitRequestFullscreen': 'webkitfullscreenchange',
                'mozRequestFullScreen': 'mozfullscreenchange',
                'msRequestFullscreen': 'msfullscreenchange',
            }
            const roots = document.documentElement
            let evtName = ''
            for (let key in fullscreenEvts) {
                if (roots[key]) {
                    evtName = fullscreenEvts[key]
                    break
                }
            }
            if (document.addEventListener) {
                document.addEventListener(evtName, this.fullListen)
            }
            document.querySelector("body").style.background = "#000"
            // 执行进度条
            this.startLineupBar()
            // 获取用户信息,APP环境，直接走排队方法，否则视为扫码进入，走接口获取用户信息
            if (geturlparam().get("sourceType") === "app") {
                this.getSceneId()
            } else {
                this.getUserInfo()
            }
            initSource((source) => {
                if (source) {
                    if(source.vdnUrl){
                        getCdnUrl(source.vdnUrl,(obj)=>{
                            this.setState({
                                source: obj
                            })
                        })
                        return
                    }
                    this.setState({ source })
                }
            })
        }
    }

    getSceneId = () => {
        let sceneId = geturlparam().get("sceneId") || "undefined"

        axios.post(orgin + `/scenario/route`, { sceneId: sceneId }).then((res) => {
            if (res.status == 200) {
                const { data } = res.data
                sceneId = data.sceneId
                this.setState({
                    sceneId: sceneId
                }, () => {
                    this.lineup()
                })
            } else {
                this.lineup()
            }
        }).catch((err) => {
            this.lineup()
        })
    }

    getUserInfo = () => {
        let sceneId = geturlparam().get("sceneId") || "undefined"
        // 获取场景id
        let pro1 = axios.post(orgin + `/scenario/route`, { sceneId: sceneId })
        // 根据cookie获取用户信息
        let pro2 = axios({
            method: 'post',
            url: orgin + "/login/getInfoByCookie",
            data: { requestId: v4(), isGetInfo: true },
            headers: {
                'token': Cookies.get('token')
            }
        })
        Promise.all([pro1, pro2]).then(([res1, res2]) => {
            sceneId = "1"
            if (res1.status == 200) {
                const { data } = res1.data
                sceneId = data.sceneId
            }

            if (res2.status == 200 && !res2.data.success) {
                this.showToast("登录失效，请重新登录！")
                Cookies.remove('token')
                // this.loginFaild = setTimeout(() => {
                //     this.props.history.replace("/login" + window.location.search)
                // }, 1500)
                return false
            }
            if (res2.status == 200) {
                this.setState({
                    userInfo: res2.data.data,
                    sceneId
                }, () => {
                    this.lineup()
                })
            }
        }).catch(() => {
            this.showToast("登录失效，请重新登录！")
        })
    }

    playEnd = () => {
        console.log("视频播放结束")
        const { gcsReady } = this.state
        if (gcsReady) {
            try {
                videoRemoveEventListener(document.querySelector("#lineupVideo"))
            } catch (err) { }
            this.setState({
                voiceStatus: true
            }, () => {
                this.setState({
                    videoPlayEnd: true
                })
            })
        } else {
            this.setState({
                videoPlayEnd: true
            })
        }

    }

    startLineupBar = () => {
        const _this = this
        this.barInterval = setInterval(() => {
            this.setState((v) => {
                if (v.lineupBar < 100) {
                    return {
                        lineupBar: v.lineupBar + _this.randowNum(v.lineupBar, v.lineupSuccess)
                    }
                } else {
                    clearInterval(this.barInterval)
                }
            })
        }, 1000)
    }

    randowNum = (e, lineupSuccess) => {
        if (lineupSuccess) {
            if (e < 75) {
                return Math.floor(Math.random() * 10)
            }
            if (e < 90) {
                return Math.floor(Math.random() * 5)
            }
            if (e < 99) {
                return Math.floor(Math.random() * 2)
            }
            return 0
        } else {
            if (e < 60) {
                return Math.floor(Math.random() * 10)
            }
            if (e < 70) {
                return Math.floor(Math.random() * 5)
            }
            if (e < 79) {
                return Math.floor(Math.random() * 2)
            }
            return 0
        }
    }

    listenWindowClose = () => {
        let _beforeUnload_time = 0, _unload_time = 0
        window.addEventListener('beforeunload', () => {
            _beforeUnload_time = new Date().getTime()

        })
        window.addEventListener('unload', () => {
            _unload_time = new Date().getTime()
            if (_unload_time - _beforeUnload_time <= 1) {
                // 关闭浏览器执行的方法
                console.log("浏览器关闭")
                try {
                    console.log("页面刷新，调用stop");
                    this.meetSDK.stop()
                    document.querySelector("#renderGcsDom").innerHTML = ""
                    document.querySelector("#renderGcsDom").style.width = 0
                    this.meetSDK = null
                } catch (err) { }

            } else {

            }

        })
    }

    fullListen = () => {
        let status = document['fullscreenElement'] ||
            document['mozFullScreenElement'] ||
            document['msFullScreenElement'] ||
            document['webkitFullscreenElement'] || null
        if (status == null) {
            this.setState({
                fullscreenStatus: false
            })
        }
        else {
            this.setState({
                fullscreenStatus: true
            })
        }
    }


    // 播放失败回调
    playFailed = () => {
        this.setState({
            voiceStatus: true
        }, () => {
            document.querySelector("#lineupVideo").play().then(() => {

            }).catch(() => {
                this.setState({
                    videoPlayStatus: "failed",
                })
            })

        })
    }
    // 视频开始播放回调
    videoPlay = () => {
        this.setState({
            showVideoLoading: false
        })
    }

    // 进行排队
    getLineUp = (data) => {
        const _this = this
        const { history } = this.props
        this.interval = setInterval(() => {
            let headers = getlineupRequestHeaders(data, "/queue/enter")
            axios({
                method: "post",
                url: lineupOrgin + '/queue/enter',
                headers: headers,
                data: data
            }).then((res) => {
                if (res.data.success) {
                    if (res.data.result.isQueue) {
                        _this.setState({
                            luneupNum: res.data.result.index,
                            needLineup: true
                        })
                    } else {
                        _this.setState({
                            luneupNum: res.data.result.index,
                            needLineup: false
                        })
                        setTimeout(() => {
                            _this.setState({
                                lineupSuccess: true,
                            })
                        }, 1000);
                        setTimeout(() => {
                            _this.setState({
                                videoMessage: "央博宇宙资源准备中…"
                            })
                        }, 3000)
                        clearInterval(this.interval)
                        // this.timeouts = setTimeout(() => {
                        //     history.replace("/loadingGames" + window.location.search)
                        // }, 6000)
                        this.getInitializationGcsData(data)
                    }
                } else {
                    clearInterval(this.interval)
                }
            })
        }, 2000)
    }
    // 排队前，获取用户数据
    lineup = () => {
        const { sceneId, userInfo } = this.state
        const { nickName, userId } = userInfo || {}
        const _this = this
        let sourceType = geturlparam().get("sourceType")
        let source = geturlparam().get("sourceType")
        let userType = geturlparam().get("userType")
        if (!source) {
            source = "mobileH5"
        }

        if (sourceType === 'app') {
            const userInfo = {
                "type": 1001,
                "param": {
                    "needLogin": 1
                }
            };
            // 对从app跳转过来的进行处理
            jsbridges.callHandler('yangboNativeMethod', userInfo, function (responseData) {
                console.log("jsbridges=====>", typeof (responseData), responseData)
                try {
                    axios.post(orgin + "/tracking/record", { data: responseData, agent: navigator.userAgent })
                } catch (err) {
                    console.log("record====>", err)
                }
                const appUser = JSON.parse(responseData);
                const data = {
                    nickname: appUser.userName,
                    sceneId,
                    source,
                    uid: appUser.userId,
                    userType: 0
                }

                // 获取手机号
                if (appUser.phone && appUser.phone !== '') {
                    let datas = {
                        requestId: v4(),
                        phoneNum: appUser.phone
                    }
                    // 增加个解密手机号码的接口调用，并传递手机号码到
                    axios.post(orgin + '/user/decrypt', datas).then((res) => {
                        console.log('-------/user/decrypt-----', res);
                        if (res.data && res.data.success) {
                            // 接口返回成功
                            const mobile = res.data.data
                            _this.setState({
                                appPhoneNum: mobile,
                                appUid: appUser.userId,
                                nickname: appUser.userName,
                            })
                        } else {
                            // 接口返回失败
                            _this.setState({
                                appPhoneNum: appUser.userId
                            })
                        }
                        _this.getLineUp(data)
                    }).catch((err) => {
                        console.log('-------/user/decrypt-----err', err);
                        _this.setState({
                            appPhoneNum: appUser.userId
                        })
                        _this.getLineUp(data)
                    })
                } else {
                    _this.setState({
                        appPhoneNum: appUser.userId
                    })
                    _this.getLineUp(data)
                }
            });
        } else {
            const data = {
                nickname: nickName,
                sceneId,
                source,
                uid: userId,
                userType: userType && !isNaN(Number(userType)) ? userType - 0 : 0
            }
            _this.getLineUp(data)
        }
    }

    componentWillUnmount() {
        if (window.location.reload) {
            window.location.reload()
            return
        }
        window.removeEventListener(evt, null)
        clearInterval(this.barInterval)
        try {
            videoRemoveEventListener(document.querySelector("#lineupVideo"))
        } catch (err) { }
        clearInterval(this.interval)
        clearTimeout(this.timeouts)
        document.querySelector("body").style.background = "transparent"
        try {
            this.meetSDK.setVolume(0)
        } catch (err) { }
        try {
            console.log("调用stopStyle")
            this.meetSDK.stopStyle()
            // console.log("调用stop退出")
            // this.meetSDK.stop()
            document.querySelector("#renderGcsDom").innerHTML = ""
            document.querySelector("#renderGcsDom").style.width = 0
            // this.meetSDK = null
        } catch (err) { }
        // document.removeEventListener("fullscreenchange", this.fullListen)
        try {
            // 执行暂停事件
            document.querySelector("#lineupVideo").pause()
        } catch (err) {
            console.log(err)
        }
        const fullscreenEvts = {
            'requestFullscreen': 'fullscreenchange',
            'webkitRequestFullscreen': 'webkitfullscreenchange',
            'mozRequestFullScreen': 'mozfullscreenchange',
            'msRequestFullscreen': 'msfullscreenchange'
        }
        const roots = document.documentElement
        let evtName = ''
        for (let key in fullscreenEvts) {
            if (roots[key]) {
                evtName = fullscreenEvts[key]
                break
            }
        }
        if (document.removeEventListener) {
            document.removeEventListener(evtName, this.fullListen)
        }
    }

    // 返回按钮点击
    backButtonClick = () => {
        const { history } = this.props
        console.log(history.goBack())
    }

    // 跳转下载页时，获取uid信息，退出排队
    toDownloadApp = () => {
        let sourceType = geturlparam().get("sourceType")
        const _this = this
        if (sourceType === 'app') {
            const userInfo = {
                "type": 1001,
                "param": {
                    "needLogin": 1
                }
            };
            // 对从app跳转过来的进行处理
            jsbridges.callHandler('yangboNativeMethod', userInfo, function (responseData) {
                console.log("jsbridges=====>", responseData)
                const appUser = JSON.parse(responseData);
                const data = {
                    uid: appUser.userId,
                }
                _this.queueQuit(data)
                clearInterval(this.interval)
                clearTimeout(this.timeouts)
                window.location.href = 'https://sj.qq.com/appdetail/com.cctv.apps.museum'
            });
        } else {
            const { userInfo } = this.state
            const { userId } = userInfo
            const data = {
                uid: userId
            }
            _this.queueQuit(data)
            clearInterval(this.interval)
            clearTimeout(this.timeouts)
            window.location.href = 'https://sj.qq.com/appdetail/com.cctv.apps.museum'
        }

    }

    // 退出排队
    queueQuit = (data) => {
        clearInterval(this.interval)
        clearTimeout(this.timeouts)
        let headers = getlineupRequestHeaders(data, "/queue/quit")
        axios({
            method: "post",
            url: lineupOrgin + '/queue/quit',
            headers: headers,
            data: data
        }).then((res) => {
            console.log("退出排队===>", res)
        })
    }


    fullscreens = () => {
        const { fullscreenStatus } = this.state
        let element = document.documentElement;
        setTimeout(() => {
            let width = document.documentElement.clientWidth;
            let height = document.documentElement.clientHeight;
            if (width < height) {
                this.getFullListen(true)
                this.lineupRef.current.style.width = height + "px"
                this.lineupRef.current.style.height = width + "px"
                this.lineupRef.current.style.top = (height - width) / 2 + "px"
                this.lineupRef.current.style.left = 0 - (height - width) / 2 + "px"
                this.lineupRef.current.style.transform = 'rotate(90deg)'
                this.lineupRef.current.style.transformOrigin = '50% 50%'
            } else {
                this.getFullListen(false)
                this.lineupRef.current.style.width = width + "px"
                this.lineupRef.current.style.height = height + "px"
                this.lineupRef.current.style.top = 0
                this.lineupRef.current.style.left = 0
                this.lineupRef.current.style.transform = 'none'
                this.lineupRef.current.style.transformOrigin = '50% 50%'
            }
        }, 500)
        try {
            if (fullscreenStatus) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
            }
        } catch (err) {
            console.log("排队页面全屏失败====>", err)
        }
    }
    // 点击预约
    clickOrder = () => {
        const { appPhoneNum, appUid, userInfo } = this.state
        const { userId, mobile } = userInfo
        let sourceType = geturlparam().get("sourceType")
        let data = {
            requestId: v4()
        }
        if (sourceType == "app") {
            data.phoneNum = appPhoneNum
            data.userid = appUid
        } else {
            data.phoneNum = mobile
            data.userid = userId
        }
        axios.post(orgin + "/user/reservation", data).then((res) => {
            console.log("预约成功====>", res)
            if (res.data.success) {
                this.setState({
                    modalTitle: "预约成功",
                    modalContentText1: "央博宇宙会在不排队时，短信通知您，建议您闲时再来～",
                    modalStatus: true
                })
            } else {
                this.setState({
                    modalTitle: "温馨提示",
                    modalContentText1: res.data.errorMessage,
                    modalStatus: true
                })
            }
        })
    }

    getInitializationGcsData = (data) => {
        const { appPhoneNum, userInfo } = this.state
        const { mobile, sign } = userInfo

        const sourceType = geturlparam().get("sourceType")
        const mobiles = sourceType === "app" ? appPhoneNum : mobile
        const signs = sourceType === "app" ? null : sign
        const { uid, nickname } = data
        this.initializationGcs(uid, nickname, mobiles, signs)
    }

    initializationGcs = (uid, nickname, mobile, sign) => {
        const { sceneId } = this.state
        const _this = this
        let source = geturlparam().get("sourceType")
        let userType = geturlparam().get("userType")
        if (!source) {
            source = "mobileH5"
        }

        const config = {
            uid,
            nickname,
            source,
            sceneId,
            userType: userType && !isNaN(Number(userType)) ? userType - 0 : 0,
            tagId: "renderGcsDom"
        }
        if (source !== 'app' && sign) {
            config.sign = sign
        }
        if (mobile) {
            config.mobile = mobile;
        }

        console.log("this.meetSDKconfig===>", config)
        this.meetSDK = new this.MeetSDKs(config)
        this.meetSDK.init().then((res) => {
            console.log("SDK的init结果", res)
            if (res) {
                this.meetSDK.start()
            }
        })
        this.meetSDK.on('MeetEvent', res => {
            const { type, message } = res;
            // console.log("sdk===>", res)
            switch (type) {
                case 'ready': //        
                    _this.setState({
                        videoMessage: "准备完成，立即体验",
                        lineupBar: 100
                    })
                    // this.meetSDK.setVolume(1);//ios  
                    if (this.state.videoPlayEnd) {
                        try {
                            videoRemoveEventListener(document.querySelector("#lineupVideo"))
                        } catch (err) { }
                        _this.setState({
                            voiceStatus: true
                        })

                    }

                    setTimeout(() => {
                        _this.setState({
                            gcsReady: true
                        })
                    }, 1000)
                    break;
                case 'save': //  
                    _this.saveImg(message)
                    console.log(message); //
                    break;
                case 'share': //  
                    _this.shareImg(message)
                    console.log(message); //     
                    break;
                case 'exit': //
                    _this.goBackPage()
                    console.log(message); //        
                    break;
                case 'error': //  
                    _this.gcsError(message)
                    console.log(message); // 
                    _this.saveErrorLog({ nickName: nickname, userId: uid, mobile, userAgent: navigator.userAgent, type: geturlparam().get("sourceType") === "app" ? "APP" : "H5", ...message })
                    break;
                case 'info':
                    const { code, desc = {} } = message
                    const { rtt = 30 } = desc
                    if (code == "401020") {
                        this.setState({
                            gamesRtt: rtt
                        })
                    }
                    if (code == "904020") {
                        this.showToast("重连成功")
                    }
                    break;
                default:
                    break;

            }
        })
    }

    // 把错误日志保存在后端
    saveErrorLog = (e) => {
        axios.post(orgin + "/logger/write", { action: "gcsError", message: JSON.stringify(e) })
    }

    goBackPage = () => {
        if (!window.location.pathname.includes("home")) {
            try {
                this.meetSDK.setVolume(0)
            } catch (err) { }
            try {
                console.log("调用stopStyle")
                this.meetSDK.stopStyle()
            } catch (err) { }
            document.querySelector("#renderGcsDom").innerHTML = ""
            document.querySelector("#renderGcsDom").style.width = 0
            this.meetSDK = null
            this.props.history.goBack()
            try {
                videoRemoveEventListener(document.querySelector("#lineupVideo"))
            } catch (err) { }
        }
    }

    shareImg = (obj) => {
        let sourceType = geturlparam().get("sourceType")
        if (sourceType === 'app') {
            const userInfo = {
                "type": 1006,
                "param": {
                    "imageUrl": obj.url
                }
            };
            jsbridges.callHandler('yangboNativeMethod', userInfo, (responseData) => {
                console.log("shareImg", responseData)
                this.meetSDK.shareCallback(obj.id)
            });

        } else {
            this.showToast("该功能仅在央博App内执行")
        }
    }

    saveImg = (obj) => {
        let sourceType = geturlparam().get("sourceType")
        // 对从app跳转过来的进行处理
        if (sourceType === 'app') {
            const userInfo = {
                "type": 1009,
                "param": {
                    "imageUrl": obj.url
                }
            };
            jsbridges.callHandler('yangboNativeMethod', userInfo, (responseData) => {
                console.log("saveImg", responseData)
            });
        } else {
            this.setState({
                clickSaveImgStatus: true,
                saveImgUrl: obj.url
            })
        }

    }

    gcsError = (message) => {
        const { desc, code } = message
        console.log("显示错误", desc, code)
        if (code == "501030" || code == "501041" || code == "904010" || code == "904020" || code == "904030") {
            this.showToast(desc)
            return
        }
        if (code == "903010" || code == "903020" || code == "510002" || code == "603010" || code == "501040") {
            this.setState({
                showModal: true,
                modalContentText: desc,
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "901080") {
            this.setState({
                showModal: true,
                modalContentText: "欢迎继续体验~",
                modalBtnNeedBack: false
            })
            return
        }
        if (code == "510001" || code == "501053") {
            this.setState({
                showModal: true,
                modalContentText: "会话停止中，请1分钟以后再试",
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "510003") {
            this.setState({
                showModal: true,
                modalContentText: "非正常请求！",
                modalBtnNeedBack: true
            })
            return
        }
        if (code == "904030") {
            this.setState({
                showModal: true,
                modalContentText: "重连失败，请1分钟以后再试",
                modalBtnNeedBack: true
            })
            return
        }
        this.showToast("服务器异常，请稍后重试")
    }

    showToast = (e) => {
        if (!this.toastTime) {
            this.setState({
                showToast: true,
                toastText: e,
            })
            this.toastTime = setTimeout(() => {
                this.setState({
                    showToast: false,
                    toastText: "",
                })
                this.toastTime = null
            }, 3000)
        }
    }

    getFullListen = (e) => {
        this.setState({
            rotateStatus: e
        })
    }
    render() {
        const str = "点击下载央博App>"
        const downLoadText = <span>，推荐使用App体验更流畅，<span style={{ color: "rgba(219, 190, 119, 1)" }} onClick={() => { this.toDownloadApp() }}>{str}</span></span>
        const {
            luneupNum, voiceStatus, sourceType,
            lineupSuccess, needLineup, showVideoLoading,
            videoPlayStatus, modalStatus, modalTitle,
            modalContentText1, fullscreenStatus, gcsReady,
            clickSaveImgStatus, saveImgUrl, showModal, modalContentText, modalBtnNeedBack,
            showToast, toastText, rotateStatus, videoMessage, lineupBar, videoPlayEnd,
            source, gamesRtt
        } = this.state
        return <div className='lineup_page' >
            <div className='lineup_content'
                ref={this.lineupRef}
                style={{
                    zIndex: (!gcsReady || !videoPlayEnd) || clickSaveImgStatus || showModal || showToast ? 1000 : 0
                }}>
                {
                    (!gcsReady || !videoPlayEnd) ? <div style={{ zIndex: (!gcsReady || !videoPlayEnd) ? 1000 : 0 }}>
                        <video id="lineupVideo" className="video" autoPlay={false} muted={voiceStatus} loop
                            poster={source.poster}
                            x5-playsinline="true"
                            playsInline
                            webkit-playsinline="true"
                            src={source.video}></video>
                        {
                            showVideoLoading ? <div className='lineupVideo_loading_box' onClick={() => {
                                if (videoPlayStatus === "failed") {
                                    this.setState({
                                        videoPlayStatus: "loading",
                                        voiceStatus: false
                                    }, () => {
                                        document.querySelector("#lineupVideo").play()
                                    })
                                }
                            }}>
                                <img className={videoPlayStatus == "failed" ? "lineupVideoLoading_box_failed" : "lineupVideoLoading_box_loading"} src={videoPlayStatus == "failed" ? "https://img.alicdn.com/imgextra/i3/O1CN01u8uIMq1lUWdG35xpO_!!6000000004822-2-tps-44-46.png" : "https://img.alicdn.com/imgextra/i1/O1CN01p2X0Wc1WvnzPx3Qkp_!!6000000002851-2-tps-78-78.png"} alt="" />
                                <div>{videoPlayStatus == "failed" ? "点击播放" : "视频加载中…"}</div>
                            </div> : null
                        }
                        <div className='lineup_detail'>
                            {
                                !needLineup ? <div className='lineup_detail_into'>
                                    {videoMessage}
                                </div> : <Fragment>
                                    <div className='lineup_desc'>
                                        <div>正在排队，请等待…</div>
                                        <div>如果出现断网、刷新页面等情况需要重排队哦{sourceType === "app" ? "" : downLoadText}</div>
                                    </div>
                                    <br />
                                    <span className='lineup_position'>当前位置：<span style={{ color: "rgba(219, 190, 119, 1)", fontWeight: 500 }}>{luneupNum}</span></span>
                                </Fragment>
                            }

                        </div>

                        <BackButton text='返回' onClick={() => { this.backButtonClick() }} />
                        <div className='lineup_bottom_mask'>
                            <div className='lineup_bar'>
                                <div className='lineup_bar_box'>
                                    <div className='lineup_bar_content' style={{ width: `${lineupBar}%` }}></div>
                                </div>
                                <div className='lineup_bar_num'>加载中… {lineupBar}%</div>
                            </div>
                            {/* 声音按钮 */}
                            <img className='lingup_voice' src={!voiceStatus ? 'https://img.alicdn.com/imgextra/i2/O1CN01kksLzb26vBdDxxGCd_!!6000000007723-2-tps-190-190.png' : "https://img.alicdn.com/imgextra/i4/O1CN0101FFlc25Hy6GF0ElD_!!6000000007502-2-tps-190-190.png"}
                                onClick={() => {
                                    this.setState({
                                        voiceStatus: !voiceStatus
                                    })
                                }} />
                            {
                                needLineup ? <div className='lineup_order' onClick={() => { this.clickOrder() }}></div> : null
                            }
                            {
                                !needLineup && gcsReady
                                    // true
                                    ? <div className='lineup_skip' onClick={() => { this.playEnd() }}>跳过</div> : null
                            }
                        </div>
                        {
                            modalStatus ? <ModalMobile title={modalTitle} contentText={modalContentText1} closed={() => { this.setState({ modalStatus: false }) }} /> : null
                        }
                        {/* {
                            geturlparam().get("sourceType") === "app" || isIos() ? null : <img className='lineup_fullscreen_btn' onClick={() => { this.fullscreens() }} src={fullscreenStatus ? "https://img.alicdn.com/imgextra/i3/O1CN01jNOa4K1RsX5B4wuNv_!!6000000002167-2-tps-208-208.png" : "https://img.alicdn.com/imgextra/i4/O1CN01cWjDVd1MXr7RKK830_!!6000000001445-2-tps-208-208.png"} alt="" />
                        } */}

                    </div> : null
                }
                {
                    clickSaveImgStatus ? <div className='saveImg_box'>
                        <div className='saveImg_content'>
                            <img className='saveImg_img' src={saveImgUrl} alt="" />
                            <div className='saveImg_title'>
                                <img src="https://img.alicdn.com/imgextra/i1/O1CN01kpIc161saCrCRbAVX_!!6000000005782-2-tps-36-36.png" alt="" />
                                <span>长按保存图片</span>
                            </div>
                            <img onClick={() => { this.setState({ clickSaveImgStatus: false }) }} className='saveImg_closed' src="https://img.alicdn.com/imgextra/i2/O1CN01Er2qjt27o99j7c1th_!!6000000007843-2-tps-91-91.png" alt="" />
                        </div>
                    </div> : null
                }
                {
                    showModal ? <Modal contentText={modalContentText} closed={() => {
                        this.setState({ showModal: false })
                        // modal的弹框，点击我知道了，是否返回上一页
                        if (modalBtnNeedBack) {
                            this.goBackPage()
                            // this.props.history.goBack()
                        } else {
                            console.log("调用了设置声音")
                            this.meetSDK.setVolume(1)
                        }
                    }} /> : null
                }
                {
                    showToast ? <Toast message={toastText} /> : null
                }

            </div>
            {
                geturlparam().get("sourceType") === "app" || isIos() ? null : <img className={` ${rotateStatus ? "loadingGames_fullscreen_btn" : "loadingGames_fullscreen_btn_false"}`} onClick={() => { this.fullscreens() }} src={fullscreenStatus ? "https://img.alicdn.com/imgextra/i3/O1CN01jNOa4K1RsX5B4wuNv_!!6000000002167-2-tps-208-208.png" : "https://img.alicdn.com/imgextra/i4/O1CN01cWjDVd1MXr7RKK830_!!6000000001445-2-tps-208-208.png"} alt="" />
            }
            {
                (!gcsReady || !videoPlayEnd) ? null : <div className={`lineup_rtt_box ${rotateStatus ? "lineup_rtt_box_h" : "lineup_rtt_box_s"}`} style={{ color: getRttObj(gamesRtt).color }}>
                    <img src={getRttObj(gamesRtt).url} alt="" style={{ width: "12px", height: "12px" }} />
                    <span style={{ paddingLeft: "4px", paddingRight: "4px" }}>{gamesRtt}</span>
                    <span>ms</span>
                </div>
            }
        </div>
    }
}
export default Lineup;