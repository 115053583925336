import React, { Component, Fragment, useState, useEffect } from 'react';
import "./toast.css"

function GamesToastPc({ message = "服务器异常，请稍后重试" }) {
    const [tips, setTips] = useState(message)
    useEffect(() => {
        setTips(message)
    }, [message])
    return <div className='GamesToastPc_page'>{tips ? tips : "服务器异常，请稍后重试"}</div>
}
export default GamesToastPc;