import axios from "axios";
import { orgin } from "./config"
import { geturlparam } from "./index";
import jsbridges from "./jsbridge.js"

const { v4 } = require('uuid');

function getuserNumDate(){
    const sourceType = geturlparam().get("sourceType")
    if(sourceType === "app"){
        const userInfo = {
            "type": 1001,
            "param": {
                "needLogin": 1
            }
        };
        // 对从app跳转过来的进行处理
        jsbridges.callHandler('yangboNativeMethod', userInfo, function (responseData) {
            console.log("jsbridges用于埋点=====>", typeof (responseData), responseData)
            const appUser = JSON.parse(responseData);
            const data = {
                phoneStr: appUser.phone,
                requestId: v4()
            }
           axios.post(orgin+"/count/add/appPvUv",data)
        });
    }
}

export { getuserNumDate }