import React, { Component, createRef, Fragment } from 'react';
import axios from "axios";
import "./loginPcLoading.css";
import { isPC, geturlparam } from "../../tools/index";
import ToastPc from "../../component/ToastPc/index";
import { orgin } from '../../tools/config';
import Cookies from 'js-cookie'

const { v4 } = require('uuid');
let times = null
let interval1 = null
let intervals = null
class LoginPcLoading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            barNum: 0,
            loginStatus: true,
            toastMessage: "",       //错误信息文本
            toastIcon: false,
            showToastStatus: false,     //是否显示toast
            nickname: "",    // 用户昵称
        };
        this.timeout1 = ""
        this.interval1Num = 0
    }

    componentDidMount() {
        const _this = this
        intervals = setInterval(() => {
            this.setState((v) => {
                if (v.barNum < 100) {
                    return {
                        barNum: v.barNum + _this.randowNum(v.barNum)
                    }
                } else {
                    clearInterval(intervals)
                }
            })
        }, 1000)
        this.dataCommit()
    }

    randowNum = (e) => {
        if (e < 75) {
            return Math.floor(Math.random() * 10)
        }
        if (e < 90) {
            return Math.floor(Math.random() * 5)
        }
        if (e < 99) {
            return Math.floor(Math.random() * 2)
        }
        return 0
    }

    dataCommit = () => {
        const { phoneNum, smsCode, history, back, mockStatus } = this.props
        let datas = {
            requestId: v4(),
            phoneNum: phoneNum,
            smsCode: mockStatus ? 8888 : smsCode,
            channel: "pc"
        }
        axios.post(orgin + "/login/commit", datas, { timeout: 5000 }).then((res) => {
            console.log("commitSuccess===>",res)
            if (res.data.success) {
                const { data = {} } = res.data
                if (data.status == "success") {
                    this.setState({
                        barNum: 100,
                        nickname: res.data.data.nickName
                    })
                    Cookies.set("token",res.data.data.session,{ expires: 15 })
                    this.timeout1 = setTimeout(() => {
                        let url = `/lineupPc`+window.location.search
                        history.replace(url)
                    }, 2000)
                }
                if (data.status == "commit") {
                    this.getUderInfo()
                }
                if (data.status == "failed") {
                    this.showToast(res.data.errorMessage)
                    console.log("requestFailed====>", res)
                    clearInterval(interval1)
                    clearInterval(intervals)
                    setTimeout(() => {
                        back()
                    }, 1500)
                }
                if (data.status == "uncommit") {
                    this.setState({
                        loginStatus: false
                    })
                    clearInterval(interval1)
                    clearInterval(intervals)
                }
            } else {
                this.showToast(res.data.errorMessage)
                clearInterval(interval1)
                clearInterval(intervals)
                setTimeout(() => {
                    back()
                }, 1500)
            }
        }).catch((err) => {
            this.showToast("登录失败，请重试")
            clearInterval(interval1)
            clearInterval(intervals)
            setTimeout(() => {
                back()
            }, 1500)
        })
    }

    getUderInfo = () => {
        const { phoneNum, history, back, mockStatus } = this.props
        interval1 = setInterval(() => {
            console.log("轮循的次数", this.interval1Num)
            this.interval1Num = this.interval1Num + 1
            if (this.interval1Num > 15) {
                clearInterval(interval1)
                clearInterval(intervals)
                this.showToast("登录失败，请重试")
                setTimeout(() => {
                    back()
                }, 1500)
                return
            }
            let data = {
                requestId: v4(),
                phoneNum: phoneNum,
            }
            axios.post(orgin + "/login/queryLoginRes", data).then((res) => {
                if (res.data.success && res.data.data && res.data.data.status == "success") {
                    clearInterval(interval1)
                    clearInterval(intervals)
                    this.setState({
                        barNum: 100,
                        nickname: res.data.data.nickName
                    })
                    Cookies.set("token",res.data.data.session,{ expires: 15 })
                    interval1 = null
                    this.timeout1 = setTimeout(() => {
                        let url = `/lineupPc`+window.location.search
                        history.replace(url)
                    }, 2000)
                }
                if (res.data.data && res.data.data.status == "failed") {
                    clearInterval(interval1)
                    clearInterval(intervals)
                    this.showToast(res.data.errorMessage)
                    setTimeout(() => {
                        back()
                    }, 1500)
                }
            })
        }, 2000);

    }

    componentWillUnmount() {
        clearInterval(interval1)
        clearInterval(intervals)
        clearTimeout(this.timeout1)
    }

    showToast = (e, success) => {
        if (!times) {
            this.setState({
                showToastStatus: true,
                toastMessage: e,
                toastIcon: success ? true : false
            })
            times = setTimeout(() => {
                this.setState({
                    showToastStatus: false,
                    toastMessage: "",
                    toastIcon: false
                })
                times = null
            }, 1500)
        }
    }

    closeModal = () => {
        const { back } = this.props
        clearInterval(interval1)
        clearInterval(intervals)
        back()
    }

    getdate = () => {
        let date = new Date()
        let y = date.getFullYear()
        let m = date.getMonth() + 1
        let d = date.getDate()
        return y + "." + m + "." + d
    }

    render() {
        const { barNum, loginStatus, showToastStatus, toastMessage, toastIcon, nickname } = this.state
        const { back, sceneDesc } = this.props
        return <div className='loginPcLoading_mask'>
            <div className='loginPcLoadingPage'>
                <img className='loginPcLoading_close'
                    onClick={() => { this.closeModal() }}
                    src="https://img.alicdn.com/imgextra/i2/O1CN01HpbJyb1QVLPvRI0IL_!!6000000001981-2-tps-80-80.png" alt="" />
                {
                    loginStatus ? <Fragment>
                        {/* 标题 */}
                        <div className='loginPcLoading_title'>
                            <div className='loginPcLoading_title_top'>{barNum >= 100 ? "登录成功" : "登录中"}</div>
                            <div className='loginPcLoading_title_bottom'>{barNum >= 100 ? "您的央博宇宙身份已解锁，欢迎加入！" : "正在解锁您的央博宇宙身份…"}</div>
                            <div className='loginPcLoading_title_bar'>
                                <img className='loginPcLoading_title_bar_imgBox' src="https://img.alicdn.com/imgextra/i4/O1CN010J1nSx1cvhamaI0rV_!!6000000003663-2-tps-364-37.png" alt="" />
                                <div
                                    style={{ width: `${barNum * 0.85 + 0.01}%` }}
                                    className='loginPcLoading_title_bar_bar'></div>
                                <img
                                    style={{ left: `${4 + barNum * 0.81}%` }}
                                    className='loginPcLoading_title_bar_imgHead' src="https://img.alicdn.com/imgextra/i3/O1CN01hVw3k21cVb16lLFA0_!!6000000003606-2-tps-66-70.png" alt="" />
                            </div>
                            <div className='loginPcLoading_title_tipsNum'>{barNum}%</div>
                        </div>
                        <div className='loginPcLoading_card'>
                            <img className='loginPcLoading_card_img' src={barNum >= 100 ? "https://img.alicdn.com/imgextra/i4/O1CN01VSdJbE1aLOycbYwkl_!!6000000003313-2-tps-436-486.png" : "https://img.alicdn.com/imgextra/i3/O1CN01ipxcTY1L6XtKzkAGW_!!6000000001250-2-tps-436-486.png"} alt="" />
                            {
                                barNum >= 100 ? <div className='loginPcLoading_userInfo'>
                                    <div className='loginPcLoading_userInfo_top'>
                                        <span>{nickname}</span>
                                        <span>{sceneDesc || "时空万象"}</span>
                                    </div>
                                    <div className='loginPcLoading_userInfo_bottom'>
                                        <span>央博</span>
                                        <span>{this.getdate()}</span>
                                    </div>
                                </div>
                                    :
                                    <img className='loginPcLoading_card_mask' src="https://img.alicdn.com/imgextra/i4/O1CN01WGJ5PW25rgzNjwmUE_!!6000000007580-2-tps-470-490.png" alt="" />
                            }
                        </div>
                    </Fragment> : <Fragment>
                        <img className='loginPcLoading_fail_img' src="https://img.alicdn.com/imgextra/i4/O1CN01khFPq8258oAzONtQ1_!!6000000007482-2-tps-480-528.png" alt="" />
                        <div className='loginPcLoading_fail_title'>抱歉，央博宇宙当前人气爆棚</div>
                        <div className='loginPcLoading_fail_tips' >
                            <span>请下载央博App体验，通过App体验减少排队进程，享受流畅体验</span>
                            <img onClick={() => { window.open('https://sj.qq.com/appdetail/com.cctv.apps.museum') }} src="https://img.alicdn.com/imgextra/i1/O1CN01RhE5uG1xjQv1ZhrM0_!!6000000006479-2-tps-244-68.png" alt="" />
                        </div>
                    </Fragment>
                }
                {
                    showToastStatus ? <ToastPc message={toastMessage} toastIcon={toastIcon} /> : null
                }
            </div>
        </div>
    }
}
export default LoginPcLoading;