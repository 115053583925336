import React, { Component, createRef } from 'react';
import "./index.css";
import { isPC, initSource, getCdnUrl } from "../../tools/index";
import { videoAddEventListener, videoRemoveEventListener } from "../../tools/videoEventListene"
import { videoOrgin } from "../../tools/config"

let intervals = ""
class IntroducePc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: false,
            time: 5,
            showSkip: false,
            voiceStatus: false,
            showVideoLoading: true,
            source: {}
        };
        this.videoRef = createRef()
        this.videoIndex = Math.floor(Math.random() * 2)
    }

    componentDidMount() {
        if (!isPC()) {
            this.props.history.replace("/home"+window.location.search)
        } else {
            videoAddEventListener(document.querySelector(".introducePcVideo"),this.videoPlay,this.playFailed)
            document.querySelector(".introducePcVideo").addEventListener("ended", this.playEnd)
            initSource((source)=>{
                if(source) {
                    if(source.vdnUrl){
                        getCdnUrl(source.vdnUrl,(obj)=>{
                            this.setState({
                                source: obj
                            })
                        })
                        return
                    }
                    this.setState({source})
                }
            })
        }
        setTimeout(()=>{
            this.videoPlay()
        },this.state.time)
    }
    
    playFailed = ()=>{
        this.setState({
            voiceStatus: true
        },()=>{
            document.querySelector(".introducePcVideo").play()
        })
    }

    videoPlay=()=>{
        this.setState({
            showVideoLoading: false
        })
        if (intervals) {
            return
        }
        intervals = setInterval(() => {
            this.setState((v) => {
                if (v.time > 0) {
                    return {
                        time: v.time - 1
                    }
                } else {
                    clearInterval(intervals)
                    return {
                        showSkip: true
                    }
                }
            })
        }, 1000);
    }

    componentWillUnmount(){
        try{
            clearInterval(intervals)
            intervals = ""
        }catch(err){}
        try{
            videoRemoveEventListener(document.querySelector(".introducePcVideo"))
            document.querySelector(".introducePcVideo").removeEventListener("ended", this.playEnd)
        }catch(err){}
    }
    playEnd = () => {
        this.props.history.replace("/loginPc" + window.location.search)
    }
    render() {
        const { time, showSkip, voiceStatus, showVideoLoading, source } = this.state
        const { history } = this.props
        return <div className='introducePcPage' >
            <video 
            poster={source.poster}
            ref={this.videoRef}
             className="introducePcVideo" muted={voiceStatus} autoPlay
                src={source.video}></video>
                {
                    showVideoLoading ? <div className='introducePc_videoLoading' >
                         <img src="https://img.alicdn.com/imgextra/i1/O1CN01p2X0Wc1WvnzPx3Qkp_!!6000000002851-2-tps-78-78.png" alt="" />
                        <div>视频加载中…</div>
                    </div>:null
                }
            {
                showSkip ? <div onClick={this.playEnd} className='introducePc_skip'>跳过</div>
                    :
                    <div className='introducePc_time'>{time}S</div>
            }
            {/* 声音按钮 */}
            <img className='introducePc_voice' src={!voiceStatus ? 'https://img.alicdn.com/imgextra/i4/O1CN01CV2xk41YsG9bkbIx8_!!6000000003114-2-tps-82-95.png' : "https://img.alicdn.com/imgextra/i2/O1CN01aubbSZ205XGDYlwX5_!!6000000006798-2-tps-82-95.png"}
                onClick={() => {
                    this.setState({
                        voiceStatus: !voiceStatus
                    })
                }} />
        </div>
    }
}
export default IntroducePc;