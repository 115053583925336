import React, { Fragment} from "react";
import "./index.css"

function BackButton({
    text="返回",
    onClick=()=>null
}) {
    return <div className="backButton" onClick={ ()=>{ onClick() } }>
        { text }
    </div>
}

export default BackButton;