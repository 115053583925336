const {REACT_APP_BASE_HOST,REACT_APP_LINE_UP_URL} = process.env
console.log(process.env)
// const urldev = "//121.40.83.47"  // 测试环境
// const urlprod = "//metah5-service.yangbo.cctv.com" // 生产环境
// const urlStage = "//101.200.59.99" // stage 环境

// const lineupUrlDev = "https://yangbo-api-dev.51meet.com"
// const lineupUrlProd = "https://meta-api.yangbo.cctv.com"
// const lineupUrlStage = "https://yangbo-api-stg.51meet.com"
// let orgin = urlprod
// let lineupOrgin = lineupUrlProd
// if(window.location.origin.includes("meta.yangbo") || window.location.origin.includes("121.199.74.60")){
//     orgin = urlprod
//     lineupOrgin = lineupUrlProd
// }else if(window.location.origin.includes("101.200.59.99")){
//     orgin = urlStage
//     lineupOrgin = lineupUrlStage
// }else {
//     orgin = urldev
//     lineupOrgin = lineupUrlDev  
// }
const videoOrgin = ["cntv.vod.cdn.myqcloud.com","vod.cntv.myhwcdn.cn"]
let orgin = `//${REACT_APP_BASE_HOST}`
let lineupOrgin = REACT_APP_LINE_UP_URL
export { orgin, lineupOrgin,  videoOrgin }